import * as React from 'react'
import { FC, useEffect, useState } from "react";
import { Toast } from "./Index";
import * as backend from '../Backend';
import { Column, DataTableRow } from "../Components/DataTable";
import * as O from "fp-ts/lib/Option";
import { ParkingLotForm } from "../Components/ParkingLotForm";

const ParkingLotsPage: FC<{ toast: (toast: Toast) => void }> = ({ toast }) => {
  const [parkingLots, setParkingLots] = useState<any>([])
  const [showForm, setShowForm] = useState(false)
  const [currentParkingLot, setCurrentParkingLot] = useState<any>(null)

  const onShowForm = () => {
    setCurrentParkingLot(null)
    setShowForm(true)
  }

  const onLoadParkingLots = () => {
    backend.parkingLots.all().then((results) => {
      setParkingLots(results.data)
    })
  }

  const onClose = (forceReload = false) => {
    setShowForm(false)

    if (forceReload) {
      onLoadParkingLots()
    }
  }

  const forceRefreshParkingLot = async () => {
    await backend.parkingLots.forceRefreshParkingLotV2()
    toast({ type: 'good', message: 'Force Refresh done' })
  }

  useEffect(() => {
    onLoadParkingLots()
  }, []);

  const columns: Column[] = [
    ['id', 'ID'],
    ['version', 'Version'],
    ['url', 'url', ({ entity }: any) => entity.url],
    ['enabled', 'Enabled', ({ entity }: any) => entity.enabled ? "YES" : "NO"],
  ]

  const update = async (parking: any) => {
    await backend.parkingLots.enable(parking.id)
    toast({ type: 'good', message: 'Updated' })
    onLoadParkingLots()

    return O.none
  }

  const onEdit = async (parking: any) => {
    setCurrentParkingLot(parking)
    setShowForm(true)

    return O.none
  }

  const onDelete = async (parking: any) => {
    await backend.parkingLots.delete(parking.id)
    toast({ type: 'good', message: 'Deleted' })
    onLoadParkingLots()

    return O.none
  }


  const actions = [
    { action: update, label: 'Change Enable' },
    { action: onEdit, label: 'Edit' },
    { action: onDelete, label: 'Delete' }
  ]

  if (showForm) {
    return <ParkingLotForm parkingLot={currentParkingLot} onClose={onClose} />
  }

  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <button className="button is-primary" style={{marginRight: '8px'}} onClick={onShowForm}>New Parking Lot</button>
              <button className="button is-primary" onClick={forceRefreshParkingLot}>Force Refresh</button>
            </ul>
          </nav>
        </div>
      </div>
      <table className="table is-fullwidth">
        <thead>
        <tr>
          {columns.map(([_, label]) => <th key={label}>{label}</th>)}
          {actions.map((action) => <th key={action.label} />)}
        </tr>
        </thead>
        <tbody>
        {
          parkingLots.length === 0 ? <tr>
              <td>There's nothing here.</td>
            </tr> :
            parkingLots.map((u: any) => {
              return <DataTableRow key={`${u.id}-${u.enabled ? 'yes' : 'no'}`} entity={u} columns={columns}
                                   actions={actions} />
            })
        }
        </tbody>
      </table>
    </div>
  )
}

export default ParkingLotsPage