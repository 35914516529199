import { end, lit, Match, parse, query, Route, str, zero } from "fp-ts-routing";
import * as t from 'io-ts'

import * as Nav from "elm-ts/lib/Navigation";
import unionize, { ofType, UnionOf } from "unionize";

//
// Pages
//

export const AppRoutes = unionize({
  Loading: ofType<{}>(),
  Login: ofType<{}>(),
  Users: ofType<{ page?: number, emailStartsWith?: string, withBiddingAccounts?: boolean }>(),
  UserDetail: ofType<{ userId: string }>(),
  Lots: ofType<{}>(),
  LotDetail: ofType<{ lotId: string }>(),
  Auction: ofType<{}>(),
  ReferralCodes: ofType<{}>(),
  Settings: ofType<{}>(),
  Features: ofType<{}>(),
  ParkingLots: ofType<{}>(),
  AuctionAlerts: ofType<{}>(),
  Events: ofType<{}>(),
  MassNotifications: ofType<{}>(),
  SignIn: ofType<{}>(),
  SignUp: ofType<{}>(),
  SignOut: ofType<{}>(),
  Unauthorized: ofType<{}>(),
  NotFound: {},
  Reports: ofType<{}>()
});


export type AppRoute = UnionOf<typeof AppRoutes>;


// matches
const defaults = end
export const loginUrl = lit('login').then(end)
export const usersUrl = lit('users')
  .then(query(
    t.partial({ page: t.string, emailStartsWith: t.string, withBiddingAccounts: t.string })
  ))
  .then(end)
export const userDetailUrl = lit('users').then(str('userId')).then(end)
export const signInUrl = lit('SignIn').then(end)
export const signUpUrl = lit('register').then(end)
export const lotsUrl = lit('lots').then(end)
export const lotDetailUrl = lit('lots').then(str('lotId')).then(end)
export const auctionUrl = lit('auction').then(end)
export const referralCodesUrl = lit('referralCodes').then(end)
export const settingsUrl = lit('settings').then(end)
export const parkingLotsUrl = lit('parkingLots').then(end)
export const featuresUrl = lit('features').then(end)
export const auctionAlertsUrl = lit('auctionAlerts').then(end)
export const eventsUrl = lit('events').then(end)
export const massNotificationsUrl = lit('massNotifications').then(end)
export const unauthorizedUrl = lit('unauthorized').then(end)
export const reportsUrl = lit('reports').then(end)

// router
const router = zero<AppRoute>()
  .alt(defaults.parser.map(() => AppRoutes.Users({})))
  .alt(loginUrl.parser.map(() => AppRoutes.Login({})))
  .alt(usersUrl.parser.map(({ page, emailStartsWith, withBiddingAccounts }) => AppRoutes.Users({
    page: page ? parseInt(page) : 1,
    emailStartsWith,
    withBiddingAccounts: withBiddingAccounts === 'true'
  })))
  .alt(userDetailUrl.parser.map(({ userId }) => AppRoutes.UserDetail({ userId })))
  .alt(signInUrl.parser.map(() => AppRoutes.SignIn({})))
  .alt(signUpUrl.parser.map(() => AppRoutes.SignUp({})))
  .alt(lotsUrl.parser.map(() => AppRoutes.Lots({})))
  .alt(auctionUrl.parser.map(() => AppRoutes.Auction({})))
  .alt(referralCodesUrl.parser.map(() => AppRoutes.ReferralCodes({})))
  .alt(settingsUrl.parser.map(() => AppRoutes.Settings({})))
  .alt(featuresUrl.parser.map(() => AppRoutes.Features({})))
  .alt(parkingLotsUrl.parser.map(() => AppRoutes.ParkingLots({})))
  .alt(auctionAlertsUrl.parser.map(() => AppRoutes.AuctionAlerts({})))
  .alt(lotDetailUrl.parser.map(({ lotId }) => AppRoutes.LotDetail({ lotId })))
  .alt(massNotificationsUrl.parser.map(() => AppRoutes.MassNotifications({})))
  .alt(eventsUrl.parser.map(() => AppRoutes.Events({})))
  .alt(reportsUrl.parser.map(() => AppRoutes.Reports({})))


// helper
export const parseUrl = (l: Nav.Location): AppRoute => {
  return parse(router, Route.parse(l.pathname + l.search), AppRoutes.NotFound({}))
}

export const formatRoute = <A extends object>(match: Match<A>) => (route: A): string =>
  match.formatter.run(Route.empty, route).toString();



