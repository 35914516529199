import React, { FC } from "react";
import { useForm } from "react-hook-form";
import * as backend from "../Backend";

type IProps = {
  toast: any,
  onSuccess: any
}

const NewTestUserForm: FC<IProps> = ({ toast, onSuccess }) => {
  const { register, handleSubmit, errors, reset, setError, setValue } = useForm()

  const onSubmit = async (data: any) => {
    backend
      .testing
      .createUser(data)
      .then(() => reset())
      .then(() => toast({type: 'good', message: 'Test user created.'}))
      .then(() => onSuccess())
      .catch(() => setError('base', 'failed_to_create', "Failed to create user, did you use a new email?"))
  }

  function field(label: string, name: string, type: string = "text") {
    return <div className="field">
      <label htmlFor={name} className="label">{label}</label>
      <div className="control">
        {type == 'textarea' ?
          <textarea id={name} className="textarea" name={name} ref={register()}/>
          :
          <input id={name} className="input" type={type} name={name} ref={register()}/>
        }
        {errors[name] && <p className="help is-danger">{errors[name].message}</p>}
      </div>
    </div>
  }

  return (
    <div className="columns">
      <div className="column"/>
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">
            New Test User
          </div>
          <div className="panel-block" style={{backgroundColor: 'lightyellow'}}>
            <div className="container">
              <form onSubmit={handleSubmit(onSubmit)}>
                {field('Email', 'email')}
                <div>
                  Password is 'password'
                </div>

                <div className="buttons is-right">
                  <button className="button is-success" type="submit">Create</button>
                </div>
                {errors.base && <p className="help is-danger">{errors.base.message}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="column"/>
    </div>
  )
}

export default NewTestUserForm

