import {Media} from "../Backend/Models/Lot";
import React, {useEffect, useState} from "react";

const typeOptions = [
  { label: 'Audio', value: 'Media::Audio' },
  { label: 'PDF', value: 'Media::PDF' },
  { label: 'Video', value: 'Media::Video' },
]

const MediaForm = ({ media, onSubmit }: { media: Media, onSubmit: any }) => {
  const [formMedia, setFormMedia] = useState<any>()
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setFormMedia({
      ...(media?.id && { id: media?.id }),
      type: media?.type || "Media::Audio",
      title: media?.title || "",
      description: media?.description || "",
      url: media?.url || "",
      thumbnailUrl: media?.thumbnailUrl || "",
      configuration: media?.configuration || "",
    })
  }, [media]);

  if (!formMedia) {
    return null;
  }

  const onSubmitMedia = (formMediaData: any) => {
    setSaving(true)
    onSubmit(formMediaData);
  }

  return (
      <>
        <h2 className="subtitle">
          Add new media
        </h2>
        <div className="field">
          <label className="label">Type:</label>
          <div className="control">
            <div className="select">
              <select defaultValue={"Media::Audio"} onChange={(e) => setFormMedia({...formMedia, type: e.target.value})}>
                {typeOptions.map((o) => <option key={o.value} value={o.value}>{o.label}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">Title:</label>
          <div className="control">
            <input defaultValue={formMedia.title} className="input" type="text"
                   onChange={(e) => setFormMedia({...formMedia, title: e.target.value})} />
          </div>
        </div>
        <div className="field">
          <label className="label">Description:</label>
          <div className="control">
            <input defaultValue={formMedia.description} className="input" type="text"
                   onChange={(e) => setFormMedia({...formMedia, description: e.target.value})} />
          </div>
        </div>
        <div className="field">
          <label className="label">URL:</label>
          <div className="control">
            <input defaultValue={formMedia.url} className="input" type="text"
                   onChange={(e) => setFormMedia({...formMedia, url: e.target.value})} />
          </div>
        </div>
        <div className="field">
          <label className="label">Thumbnail URL:</label>
          <div className="control">
            <input defaultValue={formMedia.thumbnailUrl} className="input" type="text"
                   onChange={(e) => setFormMedia({...formMedia, thumbnailUrl: e.target.value})} />
          </div>
        </div>
        <div className="field">
          <label className="label">File:</label>
          <div className="control">
            <input type="file"
                    onChange={(e) => setFormMedia({...formMedia, fileFile: e.target.files?.[0]})}
            />
          </div>
        </div>
        {saving ? <progress className="progress is-small is-primary" max="100">40%</progress> : (
          <div className="buttons is-right">
            <button className="button is-primary" onClick={() => onSubmitMedia(formMedia)} disabled={saving}>Save</button>
          </div>
        )}
      </>
  )
}

export default MediaForm

