import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import { Toast } from "./Index";
import * as backend from '../Backend';
import {Column, DataTableRow} from "../Components/DataTable";
import * as O from 'fp-ts/lib/Option'

const FeaturesPage: FC<{ toast: (toast: Toast) => void }> = () => {
    const [features, setFeatures] = useState<any>([])
    const onLoadFeatures = () => {
        backend.features.all().then((results) => {
            setFeatures(results.data)
        })
    }

    useEffect(() => {
        onLoadFeatures()
    }, []);

    const columns: Column[] = [
        ['id', 'ID'],
        ['name', 'Name'],
        ['enabled', 'Enabled', ({ entity }: any) => entity.enabled ? "YES" : "NO"],
    ]

    const update = async (feature: any) => {
        await backend.features.update(feature.id, { feature: { enabled: !feature.enabled } })
        onLoadFeatures()

        return O.none
    }

    const actions = [
        { action: update, label: 'Change Enable' },
    ]

    return (
        <div>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    {columns.map(([_, label]) => <th key={label}>{label}</th>)}
                    {actions.map((action) => <th key={action.label}/>)}
                </tr>
                </thead>
                <tbody>
                {
                    features.length === 0 ? <tr>
                            <td>There's nothing here.</td>
                        </tr> :
                        features.map((u: any) => {
                            return <DataTableRow key={`${u.id}-${u.enabled ? 'yes' : 'no'}`} entity={u} columns={columns} actions={actions} />
                        })
                }
                </tbody>
            </table>
        </div>
    )
}

export default FeaturesPage
