import * as React from 'react'
import { FC } from 'react'
import { Toast } from "./Index";
import AuctionRoom from '../Components/AuctionRoom';


const AuctionPage: FC<{ toast: (toast: Toast) => void }> = () => {
  return <>
    <AuctionRoom />
  </>
}

export default AuctionPage
