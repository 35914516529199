import React, { FC, useState } from 'react';
import * as backend from '../Backend';
import { saveBlobToFile } from '../Util';
import { Toast } from "../Pages/Index";

type IProps = {
  toast: (toast: Toast) => void
}

const Reports: FC<IProps> = ({ toast }) => {
  const [disabled, setDisabled] = useState(false)

  async function exportUsersToCsv() {
    setDisabled(true)
    try {
      const data = await backend.user.downloadCsvUsersReport()
      saveBlobToFile('users.csv', new Blob([data]))
      setDisabled(false)
    } catch(err) {
      toast({ type: 'bad', message: err.message })
    }
  }


  return <>
    <h1>Reports</h1>
    <div className="buttons are-medium">
      <button className="button is-primary" onClick={exportUsersToCsv} disabled={disabled}>Export users</button>
    </div>
  </>
}

export default Reports
