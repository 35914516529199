import React, { useCallback, useEffect, useState } from 'react';
import * as O from 'fp-ts/lib/Option'
import * as backend from '../Backend';
import { User } from '../Backend/Models/User';
import { Column } from "../Components/DataTable";
import { DataTableControlled } from "../Components/DataTableControlled";
import NewTestUserForm from "../Components/NewTestUserForm";
import { useDebounceCallback } from "@react-hook/debounce";
import ConfirmationModal from "../Components/ConfirmationModal";

const styleUserAcceptance = { cursor: 'pointer', color: '#CC0F35FF' }

const STATUSES: any = {
  'pending': 'Pending',
  'on_hold': 'On Hold',
  'rejected': 'Rejected',
  'accepted': 'Accepted',
}

const UserAcceptance = ({ user, onClick }: { user: User, onClick: any }) => {
  const styleWithColor = user.status === 'accepted' ? {color: '#6c6c6c'} : styleUserAcceptance
  const handleClick = user.status === 'accepted' ? () => {} : () => onClick(user)

  return (
      <p style={styleWithColor} onClick={handleClick}>
        Accept
      </p>
  )
}

const UsersPage: React.FC<{
  routeToUser: any,
  routeToUsers: any,
  page?: number,
  emailStartsWith?: string,
  withBiddingAccounts?: boolean,
  toast: any
}> = ({
        routeToUser,
        routeToUsers,
        page,
        emailStartsWith,
        withBiddingAccounts,
        toast
      }) => {
  const [internalEmailStartsWith, setEmailStartsWith] = useState(emailStartsWith)
  const [forceReload, setForceReload] = useState<any>(Math.random())
  const [showRemoveBidders, setShowRemoveBidders] = useState(false)
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false)

  const onClickAccept = (user: User) => {
    setCurrentUser(user)
    setShowAcceptConfirmation(true)
  }

  const onCloseAcceptance = () => {
    setCurrentUser(null)
    setShowAcceptConfirmation(false)
  }

  async function view(user: User) {
    routeToUser(user)
    return O.none
  }

  function clear() {
    setEmailStartsWith('')
    routeToUsers({})
  }

  const performSearch = useDebounceCallback(() =>
  {
      return routeToUsers({ page: page || 1, emailStartsWith: internalEmailStartsWith, withBiddingAccounts })}
    , 200)

  const onAcceptance = async () => {
    await backend.user.accept(currentUser!)
    setForceReload(Math.random())
    onCloseAcceptance();
  }

  useEffect(() => {
    performSearch()
  }, [internalEmailStartsWith])

  const columns: Column[] = [
    ['accept', '', ({ entity }: { entity: User }) => <UserAcceptance user={entity} onClick={onClickAccept} />],
    ['firstName', 'First Name'],
    ['lastName', 'Last Name'],
    ['email', 'Email'],
    ['createdAt', 'Created At'],
    ['status', 'Status', ({ entity }: { entity: User }) => STATUSES[entity.status]],
    ['phoneNumber', 'Phone', ({ entity }: { entity: User }) => entity.profile?.phoneNumber],
    ['referral', 'Referral', ({ entity }: { entity: User }) => entity.referralCode?.code]
  ]

  const actions = [
    { action: view, label: 'View' },
  ]

  const fetchPage = useCallback((page) => {
    return backend.user.all(page, {
      emailStartsWith: emailStartsWith || '',
      withBiddingAccounts: !!withBiddingAccounts
    })
  }, [emailStartsWith, withBiddingAccounts])

  const removeBidders = async () => {
    await backend.bidders._delete()
    toast({ type: 'good', message: 'Removed bidding accounts' })
    setShowRemoveBidders(false)
    setForceReload(Math.random())
  }

  return (
    <>
      <ConfirmationModal
        bodyText={'Are you sure you want to remove all bidders? This will prevent anyone from bidding. Do not do this during a live auction!'}
        confirmText="Remove Bidders"
        visible={showRemoveBidders}
        onConfirm={removeBidders}
        onCancel={() => setShowRemoveBidders(false)}
      />
      {currentUser && (
          <ConfirmationModal
              bodyText={`Would you like to accept ${currentUser.firstName} ${currentUser.lastName}? There's no undo.`}
              confirmText="Accept"
              visible={showAcceptConfirmation}
              onConfirm={onAcceptance}
              onCancel={onCloseAcceptance}
          />
      )}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li className="is-active"><a>Users</a></li>
        </ul>
      </nav>
      {
        process.env.REACT_APP_TESTING_TOOLS === 'true' ?
          <NewTestUserForm toast={toast} onSuccess={() => {
            setForceReload(Math.random())
          }}/>
          : null
      }
      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <input type="email"
                   className="input"
                   placeholder="Users with e-mails starting with..."
                   value={internalEmailStartsWith}
                   onChange={(e) => setEmailStartsWith(e.target.value)}
            />
          </div>
          <div className="column is-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ marginRight: '10px' }}>
              <label className="checkbox is-flex" style={{height: '100%'}}>
                <input type="checkbox" checked={withBiddingAccounts} onChange={() => {
                  routeToUsers({ page: 1, withBiddingAccounts: !withBiddingAccounts, emailStartsWith })
                }}/>
                <span className="block is-flex" style={{flexDirection: 'column', justifyContent: 'center', marginLeft: '5px'}}>With bidding accounts</span>
              </label>
            </div>
            <button className="button" onClick={clear}>Clear</button>
          </div>
        </div>
        <DataTableControlled columns={columns}
                             forceReload={forceReload}
                             fetch={fetchPage}
                             actions={actions}
                             page={page || 1}
                             onPageChange={(page) => routeToUsers({ page, emailStartsWith, withBiddingAccounts })}
        />
        <div className="columns">
          <div className="column">
            <button className="button is-danger" onClick={() => setShowRemoveBidders(true)}>Remove all Bidders</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsersPage
