import axios, { AxiosResponse } from 'axios';

var client: any;
client = axios.create();

async function patch(path: string, body: {} = {}) {
}

function putObject(url: string, object: File): Promise<any> {
  return client
    .put(url, object, { headers: { 'Content-Type': 'application/octet-stream' } })
    .then((res: AxiosResponse<any>) => res.data)
}

const storage = {
  put: putObject
}

export { storage }
