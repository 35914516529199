import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import * as React from 'elm-ts/lib/React'
import { render } from 'react-dom'
import * as component from './Pages/Index'
import * as Navigation from 'elm-ts/lib/Navigation'
// import 'react-dates/initialize';
import './Style/App.sass';

console.log("authDomain", process.env.REACT_APP_AUTH_DOMAIN);
let firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig)

const main = Navigation.programWithFlags(component.locationToMsg, component.init, component.update, component.view)

React.run(main(component.flags), dom => render(dom, document.getElementById('root')!))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
