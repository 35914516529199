import * as React from 'react'
import { FC } from 'react'
import { Column, DataTable } from "../Components/DataTable";
import * as backend from "../Backend";
import { Toast } from "./Index";


const EventsPage: FC<{ toast: (toast: Toast) => void }> = ({ toast }) => {
  const columns: Column[] = [
    ['id', 'ID'],
    ['createdAt', 'Created At'],
    ['type', 'Type'],
    ['payload', 'Payload'],
  ]

  return (
    <>
      <div className="columns is-vcentered">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li className="is-active"><a>Events</a></li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="box is-overflow">
        <DataTable columns={columns}
                   fetch={backend.event.all}
                   actions={[]}/>
      </div>
    </>
  )
}

export default EventsPage
