import React from "react";
import firebase from 'firebase'
import { useForm } from 'react-hook-form';

export function SignUpPage(props: any) {
  const { register, handleSubmit, errors } = useForm(); // initialise the hook
  const onSubmit = async (data: any) => {
    await firebase.auth()
      .createUserWithEmailAndPassword(data.email, data.password)
      .catch((err: any) => console.error(err))

    props.onSignUp();
  };

  return (
    <div className="columns">
      <div className="column"/>
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">
            Sign Up
          </div>
          <div className="panel-block">
            <div className="container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <label htmlFor="email" className="label">Email</label>
                  <div className="control has-icons-left">
                    <input className="input" name="email" ref={register({ required: true })}/>
                    <span className="icon is-small is-left">
                  <i className="fas fa-envelope"/>
                </span>
                    {errors.email && <p className="help is-danger">Email required</p>}
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="password" className="label">Password</label>
                  <div className="control has-icons-left">
                    <input className="input" name="password" type="password" ref={register({ required: true })}/>
                    <span className="icon is-small is-left">
                  <i className="fas fa-lock"/>
                </span>
                    {errors.password && <p className="help is-danger">Password required</p>}
                  </div>
                </div>

                <button className="button is-success" type="submit">Sign Up</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="column"/>
    </div>

  );
}

