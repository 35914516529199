import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import * as backend from '../Backend';
import Vimeo from '@u-wave/react-vimeo';
import {formatDateTime, objectToJsonFile} from "../Util";
import { Toast } from "./Index";
import { Lot, VimeoVideo } from "../Backend/Models/Lot";
import LotForm from "../Components/LotForm";
import { DataTable } from "../Components/DataTable";
import ConfirmationModal from '../Components/ConfirmationModal';
import FormModal from '../Components/FormModal';
import VimeoPlayerModal from '../Components/VimeoPlayerModal';
import MediaForm from "../Components/MediaForm";
import moment from "moment-timezone";

const typeOptions = [
  { label: 'Audio', value: 'Media::Audio' },
  { label: 'PDF', value: 'Media::PDF' },
  { label: 'Video', value: 'Media::Video' },
]

const LotPage: FC<{ toast: (toast: Toast) => void, lotId: string }> = ({ toast, lotId }) => {
  const [disabled, setDisabled] = useState(false);
  const [showImages, setShowImages] = useState(false)
  const [sections, setSections] = useState<null | any[]>(null)
  const [images, setImages] = useState<null | any[]>(null)
  const [comparables, setComparables] = useState<null | any[]>(null)
  const [saving, setSaving] = useState(false)

  const [showSections, setShowSections] = useState(false)
  const [showMedias, setShowMedias] = useState(false)
  const [showComparables, setShowComparables] = useState(false)
  const [showEditLot, setShowEditLot] = useState(false)

  const [showNewMedia, setShowNewMedia] = useState(false)
  const [currentMedia, setCurrentMedia] = useState<any>(null)
  const [showPreregister, setShowPreregister] = useState(false)
  const [showNewSection, setShowNewSection] = useState(false)
  const [editSectionId, setEditSectionId] = useState<string | null>(null)
  const [newSectionTitle, setNewSectionTitle] = useState('')
  const [newSectionContent, setNewSectionContent] = useState('')
  const [newSectionAudioUrl, setNewSectionAudioUrl] = useState('')
  const [newSectionShowSeeMore, setNewSectionShowSeeMore] = useState(false)
  const [newSectionTaggedContent, setNewSectionTaggedContent] = useState('')
  const [newSectionMedias, setNewSectionMedias] = useState<any[]>([])
  const [reorderSections, setReorderSections] = useState(false)
  const [sectionPositions, setSectionPositions] = useState<{ [key: string]: number }>({})

  const [showNewImage, setShowNewImage] = useState(false)
  const [newImage, setNewImage] = useState<File | undefined>(undefined)
  const [newImageWidth, setNewImageWidth] = useState<string | undefined>(undefined)
  const [newImageHeight, setNewImageHeight] = useState<string | undefined>(undefined)
  const [reorderImages, setReorderImages] = useState(false)
  const [imagePositions, setImagePositions] = useState<{ [key: string]: number }>({})

  const [imageToAddThumbnail, setImageToAddThumbnail] = useState<any | null>(false)
  const [newThumbnailImage, setNewThumbnailImage] = useState<File | undefined>(undefined)
  const [newThumbnailImageWidth, setNewThumbnailImageWidth] = useState<string | undefined>(undefined)
  const [newThumbnailImageHeight, setNewThumbnailImageHeight] = useState<string | undefined>(undefined)

  const [showNewComparable, setShowNewComparable] = useState(false)
  const [editComprableId, setEditComparableId] = useState<string | null>(null)
  const [newComparable, setNewComparable] = useState<File | undefined>(undefined)
  const [newComparableWidth, setNewComparableWidth] = useState<string | undefined>(undefined)
  const [newComparableHeight, setNewComparableHeight] = useState<string | undefined>(undefined)
  const [newComparableRow, setNewComparableRow] = useState<string>('1')
  const [newComparableDescription, setNewComparableDescription] = useState('')
  const [reorderComparables, setReorderComparables] = useState(false)
  const [comparablesPositions, setComparablesPositions] = useState<{ [key: string]: number }>({})

  const [showFullScreenImage, setShowFullScreenImage] = useState(false)
  const [showNewFullScreenImage, setShowNewFullScreenImage] = useState(false)
  const [newFullScreenImage, setNewFullScreenImage] = useState<File | undefined>(undefined)
  const [newFullScreenImageWidth, setNewFullScreenImageWidth] = useState<string | undefined>(undefined)
  const [newFullScreenImageHeight, setNewFullScreenImageHeight] = useState<string | undefined>(undefined)

  const [showMasterImage, setShowMasterImage] = useState(false)
  const [newMasterImage, setNewMasterImage] = useState<File | undefined>(undefined)
  const [newMasterImageWidth, setNewMasterImageWidth] = useState<string | undefined>(undefined)
  const [newMasterImageHeight, setNewMasterImageHeight] = useState<string | undefined>(undefined)

  const [preRegistering, setPreRegistering] = useState(false)

  const [showPublishLot, setShowPublishLot] = useState(false)
  const [showActivateAuction, setShowActivateAuction] = useState(false)
  const [showMoveToPastLots, setShowMoveToPastLots] = useState(false)
  const [canAddToPastLots, setCanAddToPastLots] = useState(false)
  const [lotRsvps, setLotRsvps] = useState<any[]>([])

  const [lot, setLot] = useState<Lot | null>(null)

  const [showFullScreenVimeo, setShowFullScreenVimeo] = useState(false)
  const [showNewFullScreenVimeo, setShowNewFullScreenVimeo] = useState(false)
  const [showMasterVimeo, setShowMasterVimeo] = useState(false)
  const [showNewMasterVimeo, setShowNewMasterVimeo] = useState(false)
  const [vimeoVideo, setVimeoVideo] = useState<VimeoVideo | null>(null)
  const [newVimeoForImageAt, setNewVimeoForImageAt] = useState(-1)
  const [newVimeoForComparableAt, setNewVimeoForComparableAt] = useState(-1)
  const [vimeoDeleteAction, setVimeoDeleteAction] = useState<Array<any>>([null, null])

  const onExportLot = () => {
    objectToJsonFile(lot, `lot-${lot?.title}-${moment().format('DD-MM-YYYY')}.json`)
  }

  function loadLot() {
    backend
      .lot
      .get(lotId)
      .then(lot => {
        setCanAddToPastLots(lot.canBeAddedToPastLots)
        return setLot(lot)
      })
      .then(() => setShowEditLot(false))
      .then(() => loadLotRsvp())
  }
  function loadLotRsvp() {
    backend
      .lot
      .getLotRsvp(lotId)
      .then((rsvps: any[]) => {
        setLotRsvps(rsvps)
      }).catch(err => {
        console.log('loadLotRsvp err', err)
      })
  }

  const onUpdateNewSectionMedia = (index: any, key: any, value: any) => {
    const medias = newSectionMedias.map((m: any, i: any) => {
      if (i === index) {
        m[key] = value
        return m
      }

      return m
    })

    setNewSectionMedias(medias)
  }

  function preRegisterAccounts() {
    setPreRegistering(true)
    setShowPreregister(false)
    backend
      .lot
      .massRegistrations(lotId)
      .then(() => toast({ type: 'good', message: 'Started pre-registering accounts. Please check back later.' }))
      .then(() => setPreRegistering(false))
  }

  function updateLotOnApp() {
    setDisabled(true);
    backend
      .lot
      .updateLotOnApp(lotId)
      .then(() => toast({ type: 'good', message: 'Update lot on App.' }))

    setTimeout(() => {
      setDisabled(false)
    }, 3000)
  }

  function toggleActive() {
    backend
      .lot
      .updateActive(lotId)({ active: !lot!.active })
      .then(setLot)
      .then(() => setShowPublishLot(false))
      .then(() => toast({ type: 'good', message: 'Lot updated' }))
  }

  function toggleAuctionLive() {
    backend
      .lot
      .updateAuctionLive(lotId)({ auctionLive: !lot!.auctionLive })
      .then(setLot)
      .then(() => setShowActivateAuction(false))
      .then(() => toast({ type: 'good', message: 'Auction status updated' }))
  }


  async function uploadMasterImage() {
    if (!newMasterImage || !newMasterImageWidth || !newMasterImageHeight) {
      return
    }
    setSaving(true)

    await backend.lot.replaceMasterImage(
      lotId,
      newMasterImage,
      { width: newMasterImageWidth, height: newMasterImageHeight }
    )


    toast({ type: 'good', message: 'Master image replaced' })
    setShowMasterImage(false)
    setNewMasterImage(undefined)
    setSaving(false)
    loadLot()
  }

  async function uploadFullScreenImage() {
    if (!newFullScreenImage || !newFullScreenImageWidth || !newFullScreenImageHeight) {
      return
    }

    await backend.lot.replaceFullScreenImage(
      lotId,
      newFullScreenImage,
      { width: newFullScreenImageWidth, height: newFullScreenImageHeight }
    )

    toast({ type: 'good', message: 'FullScreen image replaced' })
    setShowNewFullScreenImage(false)
    setNewFullScreenImage(undefined)
    setNewFullScreenImageWidth(undefined)
    setNewFullScreenImageHeight(undefined)
    loadLot()
  }

  function loadImages() {
    backend
      .lot
      .images(lotId)
      .then((images) => {
        setImagePositions(images.reduce((obj: any, i: any) => {
          obj[i.id] = i.position
          return obj
        }, {}))
        setImages(images)
      })
  }

  async function deleteSection(section: any) {
    await backend.lot.deleteSection(lotId, section.id)

    loadSections()
  }

  async function deleteImage(image: any) {
    await backend.lot.deleteImage(lotId, image.id)

    loadImages()
  }

  async function deleteComparable(image: any) {
    await backend.lot.deleteComparable(lotId, image.id)

    loadComparables()
  }

  function loadSections() {
    backend
      .lot
      .sections(lotId)
      .then((sections) => {
        setSectionPositions(sections.reduce((obj: any, i: any) => {
          obj[i.id] = i.position
          return obj
        }, {}))
        setSections(sections)
      })
  }


  function loadComparables() {
    backend
      .lot
      .comparables(lotId)
      .then((comparables) => {
        let j = 0
        setComparablesPositions(comparables.reduce((obj: any, i: any) => {
          obj[i.id] = j;
          j++
          return obj
        }, {}))
        setComparables(comparables)
      })
  }

  async function saveNewSection() {
    if (!newSectionTitle || !newSectionContent) return

    const body = {
      title: newSectionTitle,
      content: newSectionContent,
      audio_url: newSectionAudioUrl,
      show_see_more: newSectionShowSeeMore,
      tagged_content: 1,
      mediasAttributes: newSectionMedias,
    }

    if (editSectionId) {
      await backend.lot.updateSection(lotId, editSectionId, body)
    } else {
      await backend.lot.createSection(lotId, body)
    }

    setNewSectionTitle('')
    setNewSectionContent('')
    setNewSectionTaggedContent('')
    setNewSectionMedias([])
    setEditSectionId(null)
    setShowNewSection(false)
    toast({ type: 'good', message: 'Section added' })
    loadSections()
  }

  async function uploadImage() {
    if (!newImage || !newImageWidth || !newImageHeight) {
      return
    }
    setSaving(true)


    await backend
      .lot
      .addImage(lotId, newImage, {
        width: newImageWidth,
        height: newImageHeight
      })

    toast({ type: 'good', message: 'Image added' })
    setShowNewImage(false)
    setNewImage(undefined)
    setSaving(false)
    setNewImageWidth('')
    setNewImageHeight('')
    loadImages()
  }

  async function uploadThumbnailImage() {
    if (!newThumbnailImage || !newThumbnailImageWidth || !newThumbnailImageHeight) {
      return
    }

    await backend
      .lot
      .addThumbnailImage(lotId, imageToAddThumbnail.id, newThumbnailImage, {
        width: newThumbnailImageWidth,
        height: newThumbnailImageHeight
      })

    toast({ type: 'good', message: 'Image added' })
    setImageToAddThumbnail(null)
    setNewThumbnailImage(undefined)
    setNewThumbnailImageWidth('')
    setNewThumbnailImageHeight('')
    loadImages()
  }


  async function uploadComparable() {
    if ((!newComparable && !editComprableId) || !newComparableWidth || !newComparableHeight) {
      return
    }
    setSaving(true)

    const body = {
      description: newComparableDescription,
      width: newComparableWidth,
      height: newComparableHeight,
      row: newComparableRow
    }

    if (editComprableId) {
      await backend.lot.updateComparable(lotId, editComprableId, newComparable, body)
    } else {
      await backend.lot.addComparable(lotId, newComparable, body)
    }

    toast({ type: 'good', message: 'Image added' })
    setShowNewComparable(false)
    setNewComparable(undefined)
    setSaving(false)
    setNewComparableDescription('')
    setNewComparableWidth('')
    setNewComparableHeight('')
    setNewComparableRow('1')
    setEditComparableId(null)
    loadComparables()
  }

  function saveSectionOrdering() {
    backend
      .lot
      .sectionPositions(lotId, { data: sectionPositions })
      .then(() => {
        setReorderSections(false)
        loadSections()
      })

  }

  function saveImageOrdering() {
    backend
      .lot
      .imagePositions(lotId, imagePositions)
      .then(() => {
        setReorderImages(false)
        loadImages()
      })

  }

  function saveComparableOrdering() {
    backend
      .lot
      .comparablePositions(lotId, { data: comparablesPositions })
      .then(() => {
        setReorderComparables(false)
        loadComparables()
      })
  }

  function moveToPastLot(soldPrice: string, soldOn: string) {
    backend
      .pastLot
      .create({ lotId: lot!.id, soldPrice, soldOn })
      .then(() => {
        setShowMoveToPastLots(false)
        setCanAddToPastLots(false)
        toast({ type: 'good', message: 'This lot has been added to the list of past lots' })
      })
      .catch((err) => {
        setShowMoveToPastLots(false)
        setCanAddToPastLots(false)
        toast({ type: 'bad', message: 'Could not add this lot to the list of past lots' })
      })
  }

  function updateFullScreenVimeo(vimeoId: string, width?: number, height?: number) {
    backend
      .lot
      .updateFullScreenVimeo(lot!.id, vimeoId, width, height)
      .then(data => {
        setShowFullScreenVimeo(false)
        setShowNewFullScreenVimeo(false)
        lot!.fullScreenVimeoVideo = data.fullScreenVimeoVideo
        toast({ type: 'good', message: 'Vimeo video has been updated' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not update the Vimeo video' })
        console.error(err)
      })
  }

  function updateMasterVimeo(vimeoId: string, width?: number, height?: number) {
    backend
      .lot
      .updateMasterVimeo(lot!.id, vimeoId, width, height)
      .then(data => {
        setShowMasterVimeo(false)
        setShowNewMasterVimeo(false)
        lot!.masterVimeoVideo = data.masterVimeoVideo
        toast({ type: 'good', message: 'Vimeo video has been updated' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not update the Vimeo video' })
        console.error(err)
      })
  }

  function updateLotImageVimeo(lotImage: any, vimeoId: string, width: number, height: number) {
    backend
      .lot
      .updateLotImageVimeo(lot!.id, lotImage.id, vimeoId, width, height)
      .then(vimeoVideo => {
        setNewVimeoForImageAt(-1)
        lotImage.vimeoVideo = vimeoVideo
        toast({ type: 'good', message: 'Vimeo video has been updated' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not update the Vimeo video' })
        console.error(err)
      })
  }

  function updateLotComparableVimeo(lotComparable: any, vimeoId: string, width: number, height: number) {
    backend
      .lot
      .updateLotComparableVimeo(lot!.id, lotComparable.id, vimeoId, width, height)
      .then(vimeoVideo => {
        setNewVimeoForComparableAt(-1)
        lotComparable.vimeoVideo = vimeoVideo
        toast({ type: 'good', message: 'Vimeo video has been updated' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not update the Vimeo video' })
        console.error(err)
      })
  }

  function deleteFullScreenVimeo() {
    backend
      .lot
      .deleteFullScreenVimeo(lot!.id)
      .then(() => {
        setVimeoDeleteAction([null, null])
        lot!.fullScreenVimeoVideo = undefined
        toast({ type: 'good', message: 'Full Screen vimeo has been deleted' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not delete the Full Screen Vimeo video' })
        console.error(err)
      })
  }

  function deleteMasterVimeo() {
    backend
      .lot
      .deleteMasterVimeo(lot!.id)
      .then(() => {
        setVimeoDeleteAction([null, null])
        lot!.masterVimeoVideo = undefined
        toast({ type: 'good', message: 'Master video vimeo has been deleted' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not delete the Master Vimeo video' })
        console.error(err)
      })
  }

  const onAddSection = () => {
    setNewSectionTitle('')
    setNewSectionContent('')
    setNewSectionAudioUrl('')
    setNewSectionShowSeeMore(false)
    setEditSectionId(null)
    setShowNewSection(true)
  }

  const onAddMedia = (media: any) => {
    console.log("media => media", media)
    setCurrentMedia(media || {})
    setShowNewMedia(true)
  }

  const onDeleteMedia = (media: any) => {
    backend
        .lot
        .updateMultipartLot(lotId, {
          lot: {
            mediasAttributes: [{
              id: media.id,
              _destroy: 1,
            }]
          }
        })
        .then(() => {
          setCurrentMedia(null)
          setShowNewMedia(false)
          loadLot()
        })
  }

  const onSubmitMedia = (media: any) => {
    backend
      .lot
      .updateMultipartLot(lotId, {
        lot: {
          mediasAttributes: [media]
        }
      })
      .then(() => {
        setCurrentMedia(null)
        setShowNewMedia(false)
        loadLot()
      })
  }

  const onEditSection = (section: any) => {
    setNewSectionTitle(section.title)
    setNewSectionContent(section.content)
    setNewSectionAudioUrl(section.audioUrl)
    setNewSectionShowSeeMore(section.showSeeMore)
    setNewSectionMedias(section.medias)
    setEditSectionId(section.id)
    setShowNewSection(true)
  }

  const onAddComparable = () => {
    setNewComparableDescription('')
    setNewComparableWidth('')
    setNewComparableHeight('')
    setNewComparableRow('1')
    setShowNewComparable(true)
  }

  const onEditComparable = (comparable: any) => {
    setNewComparableDescription(comparable.description)
    setNewComparableWidth(comparable.width)
    setNewComparableHeight(comparable.height)
    setNewComparableRow(comparable.row)
    setEditComparableId(comparable.id)
    setShowNewComparable(true)
  }

  function deleteLotImageVimeo(lotImage: any) {
    backend
      .lot
      .deleteLotImageVimeo(lot!.id, lotImage.id)
      .then(() => {
        setVimeoDeleteAction([null, null])
        lotImage.vimeoVideo = undefined
        toast({ type: 'good', message: 'Vimeo video has been deleted' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not delete Vimeo video' })
        console.error(err)
      })
  }

  function deleteLotComparableVimeo(lotComparable: any) {
    backend
      .lot
      .deleteLotComparableVimeo(lot!.id, lotComparable.id)
      .then(() => {
        setVimeoDeleteAction([null, null])
        lotComparable.vimeoVideo = undefined
        toast({ type: 'good', message: 'Vimeo video has been deleted' })
      })
      .catch(err => {
        toast({ type: 'bad', message: 'Could not delete Vimeo video' })
        console.error(err)
      })
  }

  useEffect(() => {
    loadLot()
  }, [])

  useEffect(() => {
    if (showImages) {
      loadImages()
    }
  }, [showImages])

  useEffect(() => {
    if (showSections) {
      loadSections()
    }
  }, [showSections])


  useEffect(() => {
    if (showComparables) {
      loadComparables()
    }
  }, [showComparables])

  const videoTag = (videoId: string | undefined) =>
    <span className={`tag ${videoId ? 'is-info' : 'is-danger'}`}>{videoId ? `Video: #${videoId}` : 'No video'}</span>

  const extractFileName = (url: string) => {
    if (!url) return ''

    return url.split('/').pop()
  }

  const extractType = (url: string) => {
    if (!url) return ''

    return url.split('.').pop()
  }

  return <>
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><a href="javascript:history.back()">Lots</a></li>
        <li className="is-active"><a>{lotId}</a></li>
      </ul>
    </nav>

    {lot ?
      showEditLot ?
        <LotForm lot={lot} toast={toast} onSuccess={(_) => loadLot()} onCancel={() => setShowEditLot(false)} />
        :
        <>
          <ConfirmationModal
            bodyText={`Are you sure you want to ${lot.active ? 'UNPUBLISH' : 'PUBLISH'} lot?`}
            confirmText={lot.active ? 'Unpublish' : 'Publish'}
            visible={showPublishLot}
            onConfirm={() => toggleActive()}
            onCancel={() => setShowPublishLot(false)}
          />
          <ConfirmationModal
            bodyText={`Are you sure you want to ${lot.auctionLive ? 'DEACTIVATE' : 'ACTIVATE'} auction?`}
            confirmText={lot.auctionLive ? 'Deactivate' : 'Activate'}
            visible={showActivateAuction}
            onConfirm={() => toggleAuctionLive()}
            onCancel={() => setShowActivateAuction(false)}
          />
          <ConfirmationModal
            bodyText={`Are you sure you want to register accounts for this auction? Please ensure the AM id is correct! AM is ${lot.auctionMobilityId}`}
            confirmText={'Pre-Register Accounts'}
            visible={showPreregister}
            onConfirm={() => preRegisterAccounts()}
            onCancel={() => setShowPreregister(false)}
          />
          <FormModal
            form={[{ label: 'Enter sold price', required: true }, {
              label: 'Enter date at when the lot was sold',
              inputType: 'date',
              required: true
            }]}
            visible={showMoveToPastLots}
            confirmText={'Move to past lots'}
            onConfirm={moveToPastLot}
            onCancel={() => setShowMoveToPastLots(false)}
          />
          <FormModal
            form={[
              { label: 'Vimeo ID', inputType: 'text', required: true },
              { label: 'Width', inputType: 'number', required: false },
              { label: 'Height', inputType: 'number', required: false }
            ]}
            visible={showNewFullScreenVimeo || showNewMasterVimeo || (newVimeoForImageAt > -1) || (newVimeoForComparableAt > -1)}
            confirmText='Save'
            onCancel={() => {
              setShowNewFullScreenVimeo(false)
              setShowNewMasterVimeo(false)
              setNewVimeoForImageAt(-1)
              setNewVimeoForComparableAt(-1)
            }}
            onConfirm={(vimeoId, width, height) => {
              if (showFullScreenVimeo) {
                updateFullScreenVimeo(vimeoId, +width, +height)
              } else if (showMasterVimeo) {
                updateMasterVimeo(vimeoId, +width, +height)
              } else if (newVimeoForImageAt > -1 && images) {
                updateLotImageVimeo(images[newVimeoForImageAt], vimeoId, +width, +height)
              } else if (newVimeoForComparableAt > -1 && comparables) {
                updateLotComparableVimeo(comparables[newVimeoForComparableAt], vimeoId, +width, +height)
              }
            }}
          />
          <VimeoPlayerModal
            video={vimeoVideo?.id || ''}
            width={vimeoVideo?.width}
            height={vimeoVideo?.height}
            autoplay={true}
            loop={true}
            onCancel={() => setVimeoVideo(null)}
            toast={toast}
            visible={!!vimeoVideo}
          />
          <ConfirmationModal
            bodyText={'Are you sure you want to delete the associated Vimeo video?'}
            confirmText={'Delete'}
            visible={vimeoDeleteAction[0] !== null}
            onConfirm={() => vimeoDeleteAction[0](vimeoDeleteAction[1])}
            onCancel={() => setVimeoDeleteAction([null, null])}
          />
          <div className={`modal ${showMasterImage ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setShowMasterImage(false)} />
            <div className="modal-content">
              <div className="box">
                <div>
                  Upload new master image (png/jpg)
                </div>
                <div>
                  <input type="file" onChange={(e) => setNewMasterImage(e.target.files?.[0])} />
                </div>
                <div>
                  <label>
                    Width:
                    <input type="number" value={newMasterImageWidth}
                      onChange={(e) => setNewMasterImageWidth(e.target.value)} />
                  </label>
                  <label>
                    Height:
                    <input type="number" value={newMasterImageHeight}
                      onChange={(e) => setNewMasterImageHeight(e.target.value)} />
                  </label>
                </div>
                {saving ? (
                  <div style={{ marginTop: '20px' }}>
                    <progress className="progress is-small is-primary" max="100">40%</progress>
                  </div>
                ) : (
                  <div className="buttons is-right">
                    <button className="button is-primary" onClick={() => uploadMasterImage()}>Save</button>
                  </div>
                )}
              </div>
            </div>
            <button className="modal-close is-large" onClick={() => setShowMasterImage(false)} />
          </div>

          {showNewMedia && (
              <div className={`modal ${showNewMedia ? 'is-active' : ''}`}>
                <div className="modal-background" onClick={() => setShowNewMedia(false)} />
                <div className="modal-content">
                  <div className="box">
                    <MediaForm media={currentMedia} onSubmit={onSubmitMedia} />
                  </div>
                </div>
              </div>
          )}

          <div className={`modal ${showNewSection ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setShowNewSection(false)} />
            <div className="modal-content">
              <div className="box">
                <h2 className="subtitle">
                  Add new section
                </h2>
                <div className="field">
                  <label className="label">Title:</label>
                  <div className="control">
                    <input className="input" type="text" value={newSectionTitle}
                      onChange={(e) => setNewSectionTitle(e.target.value)} />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Content:</label>
                  <div className="control">
                    <textarea className="textarea" value={newSectionContent}
                      onChange={(e) => setNewSectionContent(e.target.value)} />

                  </div>
                </div>
                <div className="field">
                  <label className="label">Audio url:</label>
                  <div className="control">
                    <input className="input" type="text" value={newSectionAudioUrl}
                      onChange={(e) => setNewSectionAudioUrl(e.target.value)} />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Show see more:</label>
                    <input type="checkbox" checked={newSectionShowSeeMore}
                      onChange={(e) => setNewSectionShowSeeMore(e.target.checked)} />
                </div>

                <h3>Medias</h3>

                {newSectionMedias.map((m: any, i: any) => (
                    <div key={`m-${i}`}>
                      <div className="control">
                        <div className="select">
                          <select defaultValue={"Media::Audio"} onChange={(e) => onUpdateNewSectionMedia(i, 'type', e.target.value)}>
                            {typeOptions.map((o) => <option key={o.value} value={o.value}>{o.label}</option>)}
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Title:</label>
                        <div className="control">
                          <input defaultValue={m.title} className="input" type="text"
                                 onChange={(e) => onUpdateNewSectionMedia(i, 'title', e.target.value)} />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Description:</label>
                        <div className="control">
                          <input defaultValue={m.description} className="input" type="text"
                                 onChange={(e) => onUpdateNewSectionMedia(i, 'description', e.target.value)} />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">URL:</label>
                        <div className="control">
                          <input defaultValue={m.url} className="input" type="text"
                                 onChange={(e) => onUpdateNewSectionMedia(i, 'url', e.target.value)} />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Thumbnail URL:</label>
                        <div className="control">
                          <input defaultValue={m.thumbnailUrl} className="input" type="text"
                                 onChange={(e) => onUpdateNewSectionMedia(i, 'thumbnailUrl', e.target.value)} />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">File:</label>
                        <div className="control">
                          <input type="file"
                                 onChange={(e) => onUpdateNewSectionMedia(i, 'fileFile', e.target.files?.[0])}
                          />
                        </div>
                      </div>
                      {m.id && (
                          <>
                            <input type="checkbox" onChange={(e) => onUpdateNewSectionMedia(i, '_destroy', e.target.checked ? 1 : 0)} />
                            Delete
                          </>
                      )}
                      <hr/>
                    </div>
                ))}

                <div className="buttons is-right">
                  <button className="button is-primary" onClick={() => setNewSectionMedias([...newSectionMedias, {type: 'Media::Audio'}])}>Add new media</button>
                </div>


                <div className="buttons is-right">
                  <button className="button is-primary" onClick={() => saveNewSection()}>Save</button>
                </div>
              </div>
            </div>
            <button className="modal-close is-large" onClick={() => setShowNewSection(false)} />
          </div>
          <div className={`modal ${showNewFullScreenImage ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setShowNewFullScreenImage(false)} />
            <div className="modal-content">
              <div className="box">
                <div>
                  Upload new master image (png/jpg)
                </div>
                <div>
                  <input type="file" onChange={(e) => setNewFullScreenImage(e.target.files?.[0])} />
                </div>
                <div>
                  <label>
                    Width:
                    <input type="number" value={newFullScreenImageWidth}
                      onChange={(e) => setNewFullScreenImageWidth(e.target.value)} />
                  </label>
                  <label>
                    Height:
                    <input type="number" value={newFullScreenImageHeight}
                      onChange={(e) => setNewFullScreenImageHeight(e.target.value)} />
                  </label>
                </div>
                <div className="buttons is-right">
                  <button className="button is-primary" onClick={() => uploadFullScreenImage()}>Save</button>
                </div>
              </div>
            </div>
            <button className="modal-close is-large" onClick={() => setShowNewFullScreenImage(false)} />
          </div>
          <div className={`modal ${showNewImage ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setShowNewImage(false)} />
            <div className="modal-content">
              <div className="box">
                <div>
                  Upload new image (png/jpg)
                </div>
                <div>
                  <input type="file" onChange={(e) => setNewImage(e.target.files?.[0])} />
                </div>
                <div>
                  <label>
                    Width:
                    <input type="number"
                      value={newImageWidth}
                      onChange={(e) => setNewImageWidth(e.target.value)} />
                  </label>
                </div>

                <div>
                  <label>
                    Height:
                    <input type="number"
                      value={newImageHeight}
                      onChange={(e) => setNewImageHeight(e.target.value)} />
                  </label>
                </div>
                {saving ? (
                  <div style={{ marginTop: '20px' }}>
                    <progress className="progress is-small is-primary" max="100">40%</progress>
                  </div>
                ) : (
                  <div className="buttons is-right">
                    <button className="button is-primary" onClick={() => uploadImage()}>Save</button>
                  </div>
                )}
              </div>
            </div>
            <button className="modal-close is-large" onClick={() => setShowNewImage(false)} />
          </div>
          <div className={`modal ${imageToAddThumbnail ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setImageToAddThumbnail(null)} />
            <div className="modal-content">
              {imageToAddThumbnail?.thumbnail?.url ?
                <div className="box">
                  Current Thumbnail
                  <div>
                    <img src={imageToAddThumbnail.thumbnail.url} />
                  </div>
                  <div>
                    {imageToAddThumbnail.thumbnail.width} x {imageToAddThumbnail.thumbnail.height}
                  </div>
                </div>
                : null}
              <div className="box">
                <div>
                  Upload new thumbnail image (png/jpg)
                </div>
                <div>
                  <input type="file" onChange={(e) => setNewThumbnailImage(e.target.files?.[0])} />
                </div>
                <div>
                  <label>
                    Width:
                    <input type="number"
                      value={newThumbnailImageWidth}
                      onChange={(e) => setNewThumbnailImageWidth(e.target.value)} />
                  </label>
                </div>

                <div>
                  <label>
                    Height:
                    <input type="number"
                      value={newThumbnailImageHeight}
                      onChange={(e) => setNewThumbnailImageHeight(e.target.value)} />
                  </label>
                </div>
                <div className="buttons is-right">
                  <button className="button is-primary" onClick={() => uploadThumbnailImage()}>Save</button>
                </div>
              </div>
            </div>
            <button className="modal-close is-large" onClick={() => setImageToAddThumbnail(null)} />
          </div>
          <div className={`modal ${showNewComparable ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setShowNewComparable(false)} />
            <div className="modal-content">
              <div className="box">
                <div>
                  Upload new image (png/jpg)
                </div>
                <div>
                  <input type="file" onChange={(e) => setNewComparable(e.target.files?.[0])} />
                </div>
                <div>
                  Description:
                  <input type="text"
                    value={newComparableDescription}
                    onChange={(e) => setNewComparableDescription(e.target.value)} />
                  <div>
                    <label>
                      Width:
                      <input type="number"
                        value={newComparableWidth}
                        onChange={(e) => setNewComparableWidth(e.target.value)} />
                    </label>
                  </div>
                  <div>
                    <label>
                      Height:
                      <input type="number"
                        value={newComparableHeight}
                        onChange={(e) => setNewComparableHeight(e.target.value)} />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="">
                      Row:
                      <select value={newComparableRow} defaultValue={'1'} onChange={e => {
                        setNewComparableRow(e.target.value)
                      }}>
                        <option value="1">row 1</option>
                        <option value="2">row 2</option>
                      </select>
                    </label>
                  </div>
                </div>
                {saving ? (
                  <div style={{ marginTop: '20px' }}>
                    <progress className="progress is-small is-primary" max="100">40%</progress>
                  </div>
                ) : (
                  <div className="buttons is-right">
                    <button className="button is-primary" onClick={() => uploadComparable()}>Save</button>
                  </div>
                )}
              </div>
            </div>
            <button className="modal-close is-large" onClick={() => setShowNewComparable(false)} />
          </div>
          <div className="columns is-vcentered">
            <div className="column">
              <h1 className="title">{lot.title}</h1>
            </div>
            <div className="column">
              <div className="buttons is-pulled-right">
                <button className="button is-danger is-light"
                  onClick={() => setShowPublishLot(true)}>{lot.active ? 'Unpublish' : 'Publish'}</button>
                <button className="button is-danger is-light"
                  onClick={() => setShowActivateAuction(true)}>{lot.auctionLive ? 'Deactivate Auction' : 'Activate Auction'}</button>
                <button className="button is-primary is-light" disabled={disabled} onClick={() => updateLotOnApp()}>
                  Update lot on App
                </button>
                <button className="button is-primary is-light" onClick={onExportLot}>
                  Export Lot
                </button>
              </div>
              <div className="buttons is-pulled-right">
                <button className="button is-primary is-light" onClick={() => setShowEditLot(true)}>Edit</button>
                <button className="button is-primary is-light" onClick={() => setShowMasterImage(true)}>
                  Change Master Image
                </button>
                {
                  canAddToPastLots
                    ? <button className="button is-danger is-light" onClick={() => setShowMoveToPastLots(true)}>Move to
                      Past Lots</button>
                    : ''
                }
              </div>
            </div>
          </div>
          <div className="box">
            <div className="columns is-centered">
              <div className="column is-half">
                {lot.masterImageUrl ?
                  <div>
                    <img src={lot.masterImageUrl} alt="masterImage" />
                    <div>Dimensions: {lot.masterImageWidth} X {lot.masterImageHeight}</div>
                    <div>Type: {lot.masterImageUrl.split('.')?.pop()}</div>
                  </div>
                  :
                  <div><strong>No Image</strong></div>
                }
              </div>
            </div>
            <div className="columns">
              <div className="column"><strong>Artist Name</strong></div>
              <div className="column">{lot.artistName}</div>
              <div className="column"><strong>Year of Creation</strong></div>
              <div className="column">{lot.yearOfCreation}</div>
            </div>
            <div className="columns">
              <div className="column"><strong>Active?</strong></div>
              <div className="column">{lot.active ? 'Yes' : 'No'}</div>
              <div className="column"><strong>Estimate</strong></div>
              <div className="column">{lot.estimate}</div>
            </div>
            <div className="columns">
              <div className="column"><strong>Auction Starts At?</strong></div>
              <div className="column">{formatDateTime(lot.auctionStartsAt)}</div>
              <div className="column"><strong>Viewing Location</strong></div>
              <div className="column">{lot.viewingLocation}</div>
            </div>
            <div className="columns">
              <div className="column"><strong>Detail</strong></div>
              <div className="column is-three-quarters">
                <div className="is-linewrap">{lot.detail}</div>
              </div>
            </div>
            <div className="columns">
              <div className="column"><strong>Condition Report</strong></div>
              <div className="column is-three-quarters">
                <div className="is-linewrap">{lot.conditionReport}</div>
              </div>
            </div>
            <div className="columns">
              <div className="column"><strong>Comparables Section 1 Title</strong></div>
              <div className="column is-three-quarters">
                <div className="is-linewrap">{lot.comparablesSectionTitle}</div>
              </div>
            </div>
            <div className="columns">
              <div className="column"><strong>Comparables Section 2 Title</strong></div>
              <div className="column is-three-quarters">
                <div className="is-linewrap">{lot.byArtistSectionTitle}</div>
              </div>
            </div>

            <div className="columns">
              <div className="column"><strong>Hide Whitespace Carousel</strong></div>
              <div className="column is-three-quarters">
                {lot.hideWhitespaceCarousel ? "YES" : "NO"}
              </div>
            </div>
            <div className="columns">
              <div className="column"><strong>Hide Scheduling View</strong></div>
              <div className="column is-three-quarters">
                {lot.hideSchedulingView ? "YES" : "NO"}
              </div>
            </div>

            <h2>Medias <a onClick={() => setShowMedias(!showMedias)}>{showMedias ? 'Hide' : 'Show'}</a></h2>
            {showMedias ?
                <div className="box">
                  {lot.medias ? <>
                        {lot.medias.map((media) => (
                            <div key={media.id}>
                              <div className="columns">
                                <div className="column"><strong>Type</strong></div>
                                <div className="column is-three-quarters">
                                  <div className="is-linewrap">{media.type || '-'}</div>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column"><strong>Title</strong></div>
                                <div className="column is-three-quarters">
                                  <div className="is-linewrap">{media.title || '-'}</div>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column"><strong>Description</strong></div>
                                <div className="column is-three-quarters">
                                  <div className="is-linewrap">{media.description || '-'}</div>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column"><strong>Url</strong></div>
                                <div className="column is-three-quarters">
                                  <div className="is-linewrap">{media.url || '-'}</div>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column"><strong>Thumbnail Url</strong></div>
                                <div className="column is-three-quarters">
                                  <div className="is-linewrap">{media.thumbnailUrl || '-'}</div>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column"><strong>File</strong></div>
                                <div className="column is-three-quarters">
                                  <div className="is-linewrap">{media.fileUrl || '-'}</div>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column"><strong>Configuration</strong></div>
                                <div className="column is-three-quarters">
                                  <div className="is-linewrap">{media.configuration || '-'}</div>
                                </div>
                              </div>

                              <div className="buttons is-right">
                                <button className="button is-warning" onClick={() => onAddMedia(media)}>Edit</button>
                                <button className="button is-danger" onClick={() => onDeleteMedia(media)}>Delete</button>
                              </div>
                              <hr />
                            </div>
                        ))}
                        <div className="buttons is-right">
                          <button className="button" onClick={() => onAddMedia(null)}>Add</button>
                        </div>
                      </>
                      : null}
                </div>
                : null
            }
            <hr />

            <h2>Sections <a onClick={() => setShowSections(!showSections)}>{showSections ? 'Hide' : 'Show'}</a></h2>
            {showSections ?
              <div className="box">
                {sections ? <>
                  {sections.map((section) => (
                    <div key={section.id}>
                      <div>
                        <div>
                          Position {
                            reorderSections ?
                              <input type="text" value={sectionPositions[section.id]}
                                onChange={(e) => {
                                  setSectionPositions(
                                    {
                                      ...sectionPositions,
                                      [section.id]: e.target.value
                                    }
                                  )
                                }} />
                              :
                              <span>{section.position}</span>}
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column"><strong>{section.title}</strong></div>
                        <div className="column is-three-quarters">
                          <div className="is-linewrap">{section.content}</div>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column"><strong>Audio url</strong></div>
                        <div className="column is-three-quarters">
                          <div className="is-linewrap">{section.audioUrl || '-'}</div>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column"><strong>Show see more</strong></div>
                        <div className="column is-three-quarters">
                          <div className="is-linewrap">{section.showSeeMore ? "YES" : "NO"}</div>
                        </div>
                      </div>

                      <h3>Medias</h3>
                      {section.medias.map((media: any) => (
                          <div key={`section-${media.id}`}>
                            <div className="columns">
                              <div className="column"><strong>Type</strong></div>
                              <div className="column is-three-quarters">
                                <div className="is-linewrap">{media.type || '-'}</div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column"><strong>Title</strong></div>
                              <div className="column is-three-quarters">
                                <div className="is-linewrap">{media.title || '-'}</div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column"><strong>Description</strong></div>
                              <div className="column is-three-quarters">
                                <div className="is-linewrap">{media.description || '-'}</div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column"><strong>Url</strong></div>
                              <div className="column is-three-quarters">
                                <div className="is-linewrap">{media.url || '-'}</div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column"><strong>Thumbnail Url</strong></div>
                              <div className="column is-three-quarters">
                                <div className="is-linewrap">{media.thumbnailUrl || '-'}</div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column"><strong>File</strong></div>
                              <div className="column is-three-quarters">
                                <div className="is-linewrap">{media.fileUrl || '-'}</div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column"><strong>Configuration</strong></div>
                              <div className="column is-three-quarters">
                                <div className="is-linewrap">{media.configuration || '-'}</div>
                              </div>
                            </div>
                            <hr />
                          </div>
                      ))}
                      <div className="buttons is-right">
                        <button className="button is-warning" onClick={() => onEditSection(section)}>Edit</button>
                        <button className="button is-danger" onClick={() => deleteSection(section)}>Delete</button>
                      </div>
                      <hr />
                    </div>
                  ))}
                  <div className="buttons is-right">
                    {reorderSections ?
                      <>
                        <button className="button is-danger is-light" onClick={() => setReorderSections(false)}>
                          Cancel
                        </button>
                        <button className="button is-primary" onClick={() => saveSectionOrdering()}>
                          Save Ordering
                        </button>
                      </>
                      :
                      <>
                        <button className="button" onClick={() => setReorderSections(true)}>Reorder</button>
                        <button className="button" onClick={onAddSection}>Add</button>
                      </>
                    }
                  </div>
                </>
                  : null}
              </div>
              : null
            }
            <hr />
            <h2>
              Full Screen Vimeo Video <a
                onClick={() => setShowFullScreenVimeo(!showFullScreenVimeo)}>{showFullScreenVimeo ? 'Hide' : 'Show'} </a>
              {videoTag(lot.fullScreenVimeoVideo?.id)}
            </h2>
            {showFullScreenVimeo ?
              <div className="box">
                <div className="columns is-multiline">
                  {lot.fullScreenVimeoVideo ?
                    <div className="box">
                      <Vimeo video={lot.fullScreenVimeoVideo.id}
                        onError={() => toast({ type: 'bad', message: `No video found with id ${lot?.fullScreenVimeoVideo?.id} found` })}
                        loop={true}
                        autoplay={true}></Vimeo>
                      <span>Dimensions: {lot.fullScreenVimeoVideo.width} x {lot.fullScreenVimeoVideo.height}</span>
                    </div>
                    :
                    null}
                </div>
                <div className="buttons is-right">
                  <button className="button" onClick={() => setShowNewFullScreenVimeo(true)}>Change</button>
                  {lot.fullScreenVimeoVideo ? <button className="button is-danger is-light"
                    onClick={() => setVimeoDeleteAction([deleteFullScreenVimeo, null])}>Delete
                    Vimeo</button> : null}
                </div>
              </div>
              : null
            }
            <hr />

            <h2>
              Master Vimeo Video <a
                onClick={() => setShowMasterVimeo(!showMasterVimeo)}>{showMasterVimeo ? 'Hide' : 'Show'} </a>
              {videoTag(lot.masterVimeoVideo?.id)}
            </h2>
            {showMasterVimeo ?
              <div className="box">
                <div className="columns is-multiline">
                  {lot.masterVimeoVideo ?
                    <div className="box">
                      <Vimeo video={lot.masterVimeoVideo.id}
                        onError={() => toast({ type: 'bad', message: `No video found with id ${lot.masterVimeoVideo?.id} found` })}
                        loop={true}
                        autoplay={true}></Vimeo>
                      <span>Dimensions: {lot.masterVimeoVideo.width} x {lot.masterVimeoVideo.height}</span>
                    </div>
                    :
                    null}
                </div>
                <div className="buttons is-right">
                  <button className="button" onClick={() => setShowNewMasterVimeo(true)}>Change</button>
                  {lot.masterVimeoVideo ? <button className="button is-danger is-light"
                    onClick={() => setVimeoDeleteAction([deleteMasterVimeo, null])}>Delete
                    Vimeo</button> : null}
                </div>
              </div>
              : null
            }
            <hr />

            <h2>Images <a onClick={() => setShowImages(!showImages)}>{showImages ? 'Hide' : 'Show'}</a></h2>
            {showImages ?
              <div className="box">
                <div className="columns is-multiline">
                  {images ?
                    images.map((image, i) => (
                      <div key={image.id} className="column is-one-quarter">
                        <div className="card">
                          <div className="card-image">
                            <img src={image.fullSizeImageUrl} height="100" />
                          </div>
                          <div className="card-content">
                            <div>
                              Title: {extractFileName(image.fullSizeImageUrl)}
                            </div>
                            <div>
                              Position {
                                reorderImages ?
                                  <input type="text" value={imagePositions[image.id]}
                                    onChange={(e) => {
                                      setImagePositions(
                                        {
                                          ...imagePositions,
                                          [image.id]: e.target.value
                                        }
                                      )
                                    }} />
                                  :
                                  <span>{image.position}</span>}
                            </div>
                            <div>
                              Dimensions: {image.fullSizeImageWidth!} X {image.fullSizeImageHeight!}
                            </div>
                            <div className={image.thumbnailImageUrl ? '' : 'notification is-danger'}>
                              {image.thumbnailImageUrl ? '' : 'NEEDS THUMBNAIL'}
                            </div>
                            <div style={{ paddingBottom: "5px" }}>
                              {videoTag(image.vimeoVideo?.id)}
                            </div>
                            <div className="buttons">
                              <button className="button is-primary is-light"
                                onClick={() => setImageToAddThumbnail(image)}>Thumbnail
                              </button>
                              {image.vimeoVideo ? <button className="button is-primary is-light"
                                onClick={() => (image.vimeoVideo) ? setVimeoVideo(image.vimeoVideo) : setNewVimeoForImageAt(i)}>Play
                                Vimeo</button> : null}
                              <button className="button is-warning is-light"
                                onClick={() => setNewVimeoForImageAt(i)}>Change Vimeo
                              </button>
                              {image.vimeoVideo ? <button className="button is-secondary is-danger is-light"
                                onClick={() => setVimeoDeleteAction([deleteLotImageVimeo, image])}>Delete
                                Vimeo</button> : null}
                              <button className="button is-danger is-light" onClick={() => deleteImage(image)}>Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    null}
                </div>
                <div className="buttons is-right">
                  {reorderImages ?
                    <>
                      <button className="button is-danger is-light" onClick={() => setReorderImages(false)}>
                        Cancel
                      </button>
                      <button className="button is-primary" onClick={() => saveImageOrdering()}>
                        Save Ordering
                      </button>
                    </>
                    :
                    <>
                      <button className="button" onClick={() => setReorderImages(true)}>Reorder</button>
                      <button className="button" onClick={() => setShowNewImage(true)}>Add</button>
                    </>
                  }
                </div>
              </div>
              : null
            }
            <hr />
            <h2>Comparables <a
              onClick={() => setShowComparables(!showComparables)}>{showComparables ? 'Hide' : 'Show'}</a></h2>
            {showComparables ?
              <div className="box">
                <div className="columns is-multiline">
                  {comparables ?
                    comparables.map((comparable, i) => (
                      <div key={comparable.id} className="column is-one-fifth">
                        <div className="card">
                          <div className="card-image">
                            <img src={comparable.fileUrl} height="100" />
                          </div>
                          <div className="card-content">
                            <div>
                              Position {
                                reorderComparables ?
                                  <input type="text" value={comparablesPositions[comparable.id]}
                                    onChange={(e) => {
                                      setComparablesPositions(
                                        {
                                          ...comparablesPositions,
                                          [comparable.id]: e.target.value
                                        }
                                      )
                                    }} />
                                  :
                                  <span>{i}</span>}
                              <div>
                                Dimensions: {comparable.width} X {comparable.height}
                              </div>
                              <div>Row: {comparable.row}</div>
                              <div>
                                Type: {comparable.fileType}
                              </div>
                            </div>
                            <div>
                              {comparable.description}
                            </div>
                            <div style={{ paddingBottom: "5px" }}>
                              {videoTag(comparable.vimeoVideo?.id)}
                            </div>
                            <div className="buttons">
                              {comparable.vimeoVideo ? <button className="button is-primary is-light"
                                onClick={() => (comparable.vimeoVideo) ? setVimeoVideo(comparable.vimeoVideo) : setNewVimeoForComparableAt(i)}>Play
                                Vimeo</button> : null}
                              <button className="button is-warning is-light"
                                onClick={() => setNewVimeoForComparableAt(i)}>Change Vimeo
                              </button>
                              {comparable.vimeoVideo ? <button className="button is-danger is-light"
                                onClick={() => setVimeoDeleteAction([deleteLotComparableVimeo, comparable])}>Delete
                                Vimeo</button> : null}
                              <button className="button is-danger" onClick={() => deleteComparable(comparable)}>Delete
                              </button>
                              <button className="button is-warning" onClick={() => onEditComparable(comparable)}>Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    null}
                </div>
                <div className="buttons is-right">
                  {reorderComparables ?
                    <>
                      <button className="button is-danger is-light" onClick={() => setReorderComparables(false)}>
                        Cancel
                      </button>
                      <button className="button is-primary" onClick={() => saveComparableOrdering()}>
                        Save Ordering
                      </button>
                    </>
                    :
                    <>
                      <button className="button" onClick={() => setReorderComparables(true)}>Reorder</button>
                      <button className="button" onClick={onAddComparable}>Add</button>
                    </>
                  }
                </div>
              </div>
              : null
            }

          </div>


          <div className="box">
            <div className="columns is-vcentered">
              <div className="column">
                <h2 className="subtitle">Users requested a paddle <span className="new">New</span></h2>
              </div>
            </div>
            <div>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Fullname</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {lotRsvps.map((rsvp: any, index: number) => (
                    <tr key={`${index}-${rsvp.id}`}>
                      <td>{rsvp.email}</td>
                      <td>{rsvp.firstName} {rsvp.lastName}</td>
                    </tr>
                  ))}
                  {lotRsvps.length === 0 && <tr><td>There's nothing here.</td></tr>}
                </tbody>
              </table>
            </div>
          </div>


          <div className="box">
            <div className="columns is-vcentered">
              <div className="column">
                <h2 className="subtitle">Auction Registrations</h2>
              </div>
              <div className="column">
                <div className="buttons is-pulled-right">
                  <button disabled={preRegistering} onClick={() => setShowPreregister(true)}
                    className="button is-warning is-light">
                    Pre-Register Bidding Accounts
                  </button>
                </div>
              </div>
            </div>
            <DataTable
              columns={[
                ['auctionMobilityId', "Registration Auction Mobility ID"],
                ['accountName', 'Account', ({ entity }: any) => entity?.account?.name],
                ['paddle', 'Paddle'],
                ['auctionMobilityEmail', 'Account Auction Mobility Email', ({ entity }: any) => entity?.account?.auctionMobilityEmail],
                ['accountAuctionMobilityId', 'Account Auction Mobility ID', ({ entity }: any) => entity?.account?.auctionMobilityId],
                ['createdAt', 'Created At']
              ]}
              fetch={backend.lot.registrations(lotId)}
            />
          </div>
          <div className="box">
            <h2 className="subtitle">Leaderboard</h2>
            <DataTable
              rowKey={(_, i) => i.toString()}
              columns={[
                ['firstName', 'First Name'],
                ['lastName', 'Last Name'],
                ['explores', '# of Explores']
              ]}
              fetch={backend.lot.leaderboard(lotId)}
            />
          </div>
          <div className="box">
            <h2 className="subtitle">Events</h2>
            <DataTable
              columns={[
                ['type', 'Type'],
                ['createdAt', 'At'],
                ['userName', 'User', ({ entity: event } : any) => event?.payload?.userId],
              ]}
              fetch={backend.lot.events(lotId)}
            />
          </div>
        </>
      : <div>
        <progress className="progress is-small is-primary" max="100">15%</progress>
      </div>}
  </>
}

export default LotPage
