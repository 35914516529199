import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import * as backend from '../Backend';
import { Toast } from "./Index";
import { useForm } from "react-hook-form";
import { DataTable } from "../Components/DataTable";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import {massNotificationsUrl} from "../Routes";

const MassNotificationsPage: FC<{ toast: (toast: Toast) => void }> = ({ toast }) => {
  const { reset, register, handleSubmit, getValues } = useForm()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [disableSend, setDisableSend] = useState(false)
  const [showSmsForm, setShowSmsForm] = useState(false)
  const [showPushForm, setShowPushForm] = useState(false)
  const [showEmailField, setShowEmailField] = useState(false)
  const [showChannelField, setShowChannelField] = useState(false)
  const [emails, setEmails] = useState<Array<string>>([])
  const [userNotFoundError, setUserNotFoundError] = useState<{ defaultMessage: string }|null>(null)
  const [forceReload, setForceReload] = useState(1)

  const onSubmit = () => {
    setShowConfirmation(true)
  }

  const resetStates = () => {
    setShowConfirmation(false)
    setDisableSend(false)
    setShowSmsForm(false)
    setShowPushForm(false)
    setShowEmailField(false)
    setShowChannelField(false)
    setEmails([])
  }

  const send = () => {
    setDisableSend(true)
    const type = getValues('type')
    const channel = getValues('channel')
    backend
      .massNotifications
      .create(type, channel, getValues('message'), (type === 'USER_WITH_EMAIL') ? Array.from(new Set(emails)) : null, (channel === 'PUSH') ? getValues('title') : null)
      .then(() => {
        toast({ 'type': 'good', message: 'Sent Mass Notification!' })
        resetStates()
        reset()
        setForceReload(forceReload + 1)
      })
      .catch((err) => {
        setShowConfirmation(false)
        setDisableSend(false)
        const message = err?.response?.data?.message || err
        toast({ 'type': 'bad', message: `Something went wrong: ${message}` })
      })
  }

  return (
    <>
      <div className={`notification is-danger is-medium ${userNotFoundError ? 'is-visible' : 'is-hidden'}`} >
        {userNotFoundError?.defaultMessage}
        <button className="delete" onClick={() => setUserNotFoundError(null)}></button>
      </div>
      <div className={`modal ${showConfirmation ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setShowConfirmation(false)}/>
        <div className="modal-content">
          <div className="box">
            <div>
              You're about to send "{getValues('message')}" to {getValues('type')}. Are you sure? This will send message 
              by using the {getValues('channel')} channel to all the selected users.
            </div>
            <div className="buttons is-right">
              <button disabled={disableSend} className="button is-primary" onClick={send}>Send</button>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" onClick={() => setShowConfirmation(false)}/>
      </div>
      <div className="columns">
        <div className="column"/>
        <div className="column is-half">
          <div className="panel">
            <div className="panel-heading">Send Mass Notification</div>
            <div className="panel-block">
              <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <label htmlFor="type" className="label">Type</label>
                    <div className="select">
                      <select name="type" ref={register} required={true} onChange={e => {
                        const shouldShowEmailField = e.target.value === 'USER_WITH_EMAIL'
                        setShowEmailField(shouldShowEmailField)
                        setShowChannelField(!shouldShowEmailField && !!e.target.value)
                      }}>
                        <option value="">Select one</option>
                        <option value="ACCEPTED_USERS">All Accepted Users</option>
                        <option value="USERS_WITH_BIDDING_ACCOUNT">All Users with Bidding Account</option>
                        <option value="USER_WITH_EMAIL">To specific users</option>
                      </select>
                    </div>
                  </div>

                  <div className="field" hidden={!showEmailField}>
                    <label htmlFor="email" className="label">User e-mail</label>
                    <div className="control">
                      {/* <input className="input" type="mail" name="email" ref={register} required={showEmailField} onChange={e => setShowChannelField(!!e.target.value)}/> */}
                      <ReactMultiEmail
                        ref={register}
                        placeholder="Enter a comma separated list of e-mails"
                        emails={emails}
                        onChange={(_emails => {
                          setEmails(_emails)
                          setShowChannelField(!!_emails.length)
                        })}
                        validateEmail={email => isEmail(email)}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span data-tag-handle onClick={() => removeEmail(index)}>x</span>
                            </div>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="field" hidden={!showChannelField}>
                    <label htmlFor="channel" className="label">Channel</label>
                    <div className="select">
                      <select name="channel" ref={register} required={true} onChange={e => {
                        setShowSmsForm(e.target.value === 'SMS')
                        setShowPushForm(e.target.value === 'PUSH')
                      }}>
                        <option value="">Select one</option>
                        <option value="SMS">SMS</option>
                        <option value="PUSH">Push notification</option>
                      </select>
                    </div>
                  </div>

                  <div className="field" hidden={!showChannelField || !showPushForm}>
                    <label htmlFor="title" className="label">Title</label>
                    <div className="control">
                      <input className="input" name="title" type="text" ref={register} required={showPushForm}/>
                    </div>
                  </div>

                  <div className="field" hidden={!showChannelField || (!showPushForm && !showSmsForm)}>
                    <label htmlFor="message" className="label">Message</label>
                    <div className="control">
                      <input className="input" name="message" type="text" ref={register} required={true}/>
                    </div>
                  </div>

                  <button className="button is-success" type="submit" disabled={!showChannelField || (!showSmsForm && !showPushForm)}>Send</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="column"/>
      </div>
      <DataTable
        columns={[
          ['id', 'ID'],
          ['type', 'Type'],
          ['sentAt', 'Sent At'],
          ['totalItems', 'Total'],
          ['totalPendingItems', 'Pending'],
          ['totalQueuedItems', 'Queued'],
          ['totalDeliveredItems', 'Delivered'],
          ['totalUndeliveredItems', 'Undelivered'],
          ['totalFailedItems', 'Failed'],
          ['totalSentItems', 'Sent'],
        ]}
        forceReload={forceReload}
        fetch={backend.massNotifications.get}
      />

      <DataTable
        columns={[
          ['createdAt', 'At'],
          ['type', 'Type'],
          ['payload', 'Payload'],
        ]}
        forceReload={forceReload}
        fetch={backend.massNotifications.events}
      />
    </>
  )
}

export default MassNotificationsPage
