import { User } from './Models/User';
import axios, { AxiosResponse } from 'axios';
import { Page } from './Models/Page';
import * as gcp from '../Gcp';
import { SellRequest } from './Models/SellRequest';
import { BidderRegistration } from './Models/BidderRegistration';
import {
  Lot,
  ParkingLot,
  PastLot,
  UpcomingLots,
  VimeoVideo,
} from './Models/Lot';
import { Setting } from './Models/Setting';
import _ from 'lodash-es';

function toSnakeCaseKeys(obj: any): any {
  if (obj instanceof File) {
    return obj;
  } else if (_.isArray(obj)) {
    return obj.map((val) => toSnakeCaseKeys(val));
  } else if (_.isObject(obj)) {
    return _.mapValues(
      _.mapKeys(obj, (v: any, k: any) => (k === '_destroy' ? k : _.snakeCase(k))),
      (v: any) => toSnakeCaseKeys(v)
    );
  }
  return obj;
}

function deepFormData(obj: any, form: any, namespace = null): any {
  const formData = form || new FormData();
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    const propName = namespace ? `${namespace}[${key}]` : key;
    if (obj[key] instanceof File) {
      formData.append(propName, obj[key]);
    } else if (_.isObject(obj[key]) && !_.isArray(obj[key])) {
      // @ts-ignore
      deepFormData(obj[key], formData, propName);
    } else if (_.isArray(obj[key])) {
      _.each(obj[key], (item, index) => {
        if (_.isObject(item)) {
          // @ts-ignore
          deepFormData(item, formData, `${propName}[${index}]`);
        } else {
          formData.append(`${propName}[${index}]`, item);
        }
      });
    } else {
      formData.append(propName, obj[key]);
    }
  }
  return formData;
}

var deserializer = require('jsonapi-serializer').Deserializer;

let client: any;
let railsClient: any;
let getToken: any;
const apiRoot = process.env.REACT_APP_API_ROOT || 'http://localhost:8080';
const apiRailsRoot =
  process.env.REACT_APP_RAILS_API_ROOT || 'http://localhost:5000';
const RAILS_API_KEY = process.env.REACT_APP_RAILS_API_KEY!;
const RAILS_API_ID = process.env.REACT_APP_RAILS_API_ID!;

export function configure(_: () => any, token: string) {
  // DEV NOTE: This is to get the firebase user auth token. We need to use the same for both clients
  getToken = () => token;
  client = axios.create({
    baseURL: apiRoot,
    headers: {},
  });

  client.defaults.headers.get['Accept'] = 'application/json';
  client.defaults.headers.get['Accept'] = 'application/json';
  client.defaults.headers.post['Accept'] = 'application/json';
  client.defaults.headers.post['Content-Type'] = 'application/json';
  client.defaults.headers.patch['Accept'] = 'application/json';
  client.defaults.headers.patch['Content-Type'] = 'application/json';
  client.defaults.headers.put['Accept'] = 'application/json';
  client.defaults.headers.put['Content-Type'] = 'application/json';
  client.defaults.headers.delete['Accept'] = 'application/json';
  client.defaults.headers.delete['Content-Type'] = 'application/json';

  // Create a second client for the rails api
  configureRailsClient(token);
}

function configureRailsClient(_: string) {
  railsClient = axios.create({
    baseURL: apiRailsRoot,
    headers: {},
  });

  railsClient.defaults.headers.get['Accept'] = 'application/json';
  railsClient.defaults.headers.get['Accept'] = 'application/json';
  railsClient.defaults.headers.post['Accept'] = 'application/json';
  railsClient.defaults.headers.post['Content-Type'] = 'application/json';
  railsClient.defaults.headers.patch['Accept'] = 'application/json';
  railsClient.defaults.headers.patch['Content-Type'] = 'application/json';
  railsClient.defaults.headers.put['Accept'] = 'application/json';
  railsClient.defaults.headers.put['Content-Type'] = 'application/json';
  railsClient.defaults.headers.delete['Accept'] = 'application/json';
  railsClient.defaults.headers.delete['Content-Type'] = 'application/json';
}

async function post(path: string, body: {} = {}) {
  const token = await getToken();
  return client
    .post(path, body, { headers: { Authorization: `Bearer ${token}` } })
    .then((res: AxiosResponse<any>) => res.data);
}

async function patch(path: string, body: {} = {}) {
  const token = await getToken();
  return client
    .patch(path, body, { headers: { Authorization: `Bearer ${token}` } })
    .then((res: AxiosResponse<any>) => res.data);
}

async function put(path: string, body: {} = {}) {
  const token = await getToken();
  return client
    .put(path, body, { headers: { Authorization: `Bearer ${token}` } })
    .then((res: AxiosResponse<any>) => res.data);
}

async function get(path: string) {
  const token = await getToken();
  return client
    .get(path, { headers: { Authorization: `Bearer ${token}` } })
    .then((res: AxiosResponse<any>) => res.data);
}

// HTTP METHODS FOR RAILS CLIENT
async function railsPost(path: string, body: {} = {}) {
  const token = await getToken();
  return railsClient
    .post(path, body, { headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY } })
    .then((res: AxiosResponse<any>) => {
      return res.data;
    });
}

async function railsPostMultipart(path: string, body: {} = {}) {
  const token = await getToken();
  return railsClient
    .post(path, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-API-ID': RAILS_API_ID,
        'X-API-KEY': RAILS_API_KEY,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res: AxiosResponse<any>) => res.data);
}

async function railsPut(path: string, body: {} = {}) {
  const token = await getToken();
  return railsClient
    .put(path, body, { headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY } })
    .then((res: AxiosResponse<any>) => res.data);
}

async function railsPutMultiPart(path: string, body: {} = {}) {
  const token = await getToken();
  return railsClient
    .put(path, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-API-ID': RAILS_API_ID,
        'X-API-KEY': RAILS_API_KEY,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res: AxiosResponse<any>) => res.data);
}

async function railsGet(path: string, params?: any) {
  const token = await getToken();
  return railsClient
    .get(path, { params, headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY } })
    .then((res: AxiosResponse<any>) => res.data);
}

async function railsDelete(path: string) {
  const token = await getToken();
  return railsClient
    .delete(path, { headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY } })
    .then((res: AxiosResponse<any>) => res.data);
}

async function downloadFile(
  path: string,
  params: {} = {},
  headers: {} = { Accept: 'text/csv' }
) {
  const token = await getToken();
  return client
    .get(path, {
      headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY, ...headers },
      responseType: 'blob',
      params,
    })
    .then((res: AxiosResponse<any>) => res.data);
}

async function downloadImage(
  path: string,
  params: {} = {},
  headers: {} = { Accept: 'image/csv' }
) {
  const token = await getToken();
  return client
    .get(path, { headers, responseType: 'blob', params })
    .then((res: AxiosResponse<any>) => res.data);
}

async function _delete(path: string) {
  const token = await getToken();
  return client
    .delete(path, { headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY } })
    .then((res: AxiosResponse<any>) => res.data);
}

async function requestMfaVerification(user: any): Promise<any> {
  const token = await user.getIdToken();
  return axios
    .post(
      `${apiRailsRoot}/sessions/otp`,
      {},
      { headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY } }
    )
    .then((res: AxiosResponse<any>) => res.data);
}

async function confirmMfa(user: any, body: {}): Promise<any> {
  const token = await user.getIdToken();
  return axios
    .post(`${apiRailsRoot}/sessions`, body, {
      headers: { Authorization: `Bearer ${token}`, 'X-API-ID': RAILS_API_ID, 'X-API-KEY': RAILS_API_KEY },
    })
    .then((res: AxiosResponse<any>) => res.data);
}

function usersByEmail(email: string): (page: number) => Promise<Page<User>> {
  return (page) =>
    get(`/users?page=${page}&email=${encodeURIComponent(email)}`);
}

async function users(
  page: number,
  where: {
    emailStartsWith: string | null;
    withBiddingAccounts: boolean | null;
  } | null = null
): Promise<any> {
  const params: any = { page };
  if (where) {
    if (where.emailStartsWith) {
      params.email_matching = encodeURIComponent(where.emailStartsWith);
    }
    if (where.withBiddingAccounts) {
      params.can_bid = where.withBiddingAccounts;
    }
  }
  const data = await railsGet(
    `/users?${Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`
  );

  const result = await deserializeData(data);

  return {
    data: result,
    meta: data.meta,
  };
}

async function allById(userIds: string[]): Promise<User[]> {
  const data = await railsGet(`/users`, { id_in: userIds });
  return await deserializeData(data);
}

function createTestUser(body: {}): Promise<boolean> {
  return post('/a/testing/users', body);
}

async function accept(user: User): Promise<User> {
  const data = await railsPost(`/users/${user.id}/acceptance`);
  return await deserializeData(data);
}

function resetBidder(user: User): any {
  return railsPost(`/users/${user.id}/reset_bidder`);
}

function triggerApprovedEmail(user: User): any {
  return railsPost(`/users/${user.id}/bidder_registration/trigger_approved_email`);
}

function updateUserOnApp(user: User): any {
  return railsPost(`/users/${user.id}/send_event`);
}

function deleteBidders(): Promise<any> {
  return railsDelete(`/accounts/destroy_bidders`);
}

async function getPlaidAccounts(userId: String): Promise<any> {
  const data = await railsGet(`/users/${userId}/plaids/accounts`);
  return await deserializeData(data);
}

async function syncPlaidAccounts(userId: String): Promise<any> {
  await railsPost(`/users/${userId}/plaids/accounts/sync`);
}

async function financialVerificationUpdate(
  userId: String,
  body: any
): Promise<any> {
  return railsPut(
    `/users/${userId}/bidder_registration/financial_verification`,
    body
  );
}

async function updateUser(user: User, body: {}): Promise<User> {
  const req_body = toSnakeCaseKeys(body);
  const data = await railsPut(`/users/${user.id}`, req_body);
  return await deserializeData(data);
}

function updateRailsUser(user: User, body: {}): Promise<User> {
  return railsPut(`/users/${user.id}`, body);
}

async function me(): Promise<User> {
  const data = await railsGet('/users/me');
  return await deserializeData(data);
}

async function signOut(): Promise<void> {
  return railsDelete('/sessions');
}

async function getUser(userId: string): Promise<User> {
  const data = await railsGet(`/users/${userId}`);
  return await deserializeData(data);
}

async function getRailsUser(userId: string): Promise<User> {
  const data = await railsGet(`/users/${userId}`);
  return await deserializeData(data);
}

function userEvents(userId: string): (page: number) => Promise<any> {
  return async (page) => {
    const data = await railsGet(`/users/${userId}/events?page=${page}&per=10`);
    const result = await deserializeData(data);

    return {
      data: result,
      meta: data.meta || {},
    };
  };
}

async function userEventsByType(userId: string, eventType: string): Promise<any> {
  const data = await railsGet(`/users/${userId}/events?event_type=${eventType}&page=1`);
  return await deserializeData(data);
}

function userSellRequests(userId: string): (page: number) => Promise<any> {
  return async (page) => {
    const data = await railsGet(`/users/${userId}/sell_requests?page=${page}`);
    const result = await deserializeData(data);

    return {
      data: result,
      meta: data.meta || {},
    };
  };
}

async function getBidderRegistration(
  userId: string
): Promise<BidderRegistration> {
  const data = await railsGet(`/users/${userId}/bidder_registration`);
  return await deserializeData(data);
}

async function changeBidderRegistrationState(
  userId: string,
  newState: string
): Promise<BidderRegistration> {
  const data = await railsPost(`/users/${userId}/bidder_registration/states`, {
    state: newState,
  });

  return await deserializeData(data);
}

async function questionnaire(userId: string): Promise<any> {
  const data = await railsGet(`/users/${userId}/questionnaire`);
  return await deserializeData(data);
}

async function questionnaireVersions(userId: string): Promise<any> {
  return railsGet(`/users/${userId}/questionnaire/versions`);
}

async function createQuestionnaire(userId: string, body: any): Promise<any> {
  const data = await railsPost(`/users/${userId}/questionnaire`, body);
  return await deserializeData(data);
}

async function createUserAccount(userId: string, body: any): Promise<any> {
  const data = await railsPost(`/users/${userId}/accounts`, body);
  return await deserializeData(data);
}

async function promoteAccount(
  userId: string,
  accountId: string
): Promise<void> {
  const data = await railsPost(
    `/users/${userId}/accounts/${accountId}/promotions`
  );
  return await deserializeData(data);
}

function downloadCsvUsersReport(): Promise<any> {
  return downloadFile('/a/users/exportCsv');
}

async function lots(page: number): Promise<any> {
  const data = await railsGet(`/lots?page=${page}`);
  const result = await deserializeData(data);

  return {
    data: result,
    meta: data.meta,
  };
}

async function createLot(body: any): Promise<Lot> {
  const data = await railsPost(`/lots`, toSnakeCaseKeys(body));
  return await deserializeData(data);
}

async function updateLot(lotId: string, body: any): Promise<Lot> {
  const data = await railsPut(`/lots/${lotId}`, toSnakeCaseKeys(body));
  return await deserializeData(data);
}

async function updateMultipartLot(lotId: string, body: any): Promise<Lot> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  const form = deepFormData(bodyData, formData);

  const data = await railsPutMultiPart(`/lots/${lotId}`, form);
  return await deserializeData(data);
}

function updateLotActive(lotId: string): (body: any) => Promise<Lot> {
  return async (body) => {
    const data = await railsPut(`/lots/${lotId}/active`, body);
    return await deserializeData(data);
  };
}

function updateLotAuctionLive(lotId: string): (body: any) => Promise<Lot> {
  return async (body) => {
    const data = await railsPut(
      `/lots/${lotId}/auction_live`,
      toSnakeCaseKeys(body)
    );
    return await deserializeData(data);
  };
}

function updateAccountBidding(accountId: string): (body: any) => Promise<Lot> {
  return async (body) => {
    const data = await railsPut(`/accounts/${accountId}/can_bid`, body);
    return await deserializeData(data);
  };
}

async function getLot(lotId: string): Promise<Lot> {
  const data = await railsGet(`/lots/${lotId}`);
  return await deserializeData(data);
}

async function lotRegistrationsUnpaginated(lotId: string): Promise<any> {
  const data = await railsGet(`/lots/${lotId}/registrations?pagination=false`);
  return await deserializeData(data);
}

function lotRegistrations(lotId: string): (page: number) => Promise<any> {
  return async (page: number) => {
    const data = await railsGet(`/lots/${lotId}/registrations?page=${page}`);
    const result = await deserializeData(data);

    return {
      data: result,
      meta: data.meta,
    };
  };
}

function lotEvents(lotId: string): (page: number) => Promise<any> {
  return async (page) => {
    const data = await railsGet(`/lots/${lotId}/events?page=${page}`);
    const result = await deserializeData(data);

    return {
      data: result,
      meta: data.meta,
    };
  };
}

function lotBiddingRoomEvents(lotId: string): (page: number) => Promise<any> {
  return async (page) => {
    const data = await railsGet(
      `/lots/${lotId}/events?page=${page}&type_eq=ENTERED_BIDDING_ROOM`
    );
    const result = await deserializeData(data);

    return {
      data: result,
      meta: data.meta,
    };
  };
}

function lotLeaderboard(lotId: string): (page: number) => Promise<any> {
  return async (page) => {
    const data = await railsGet(`/lots/${lotId}/leaderboard?page=${page}`);
    const result = await deserializeData(data);

    return {
      data: result,
      meta: data.result,
    };
  };
}

async function upcomingLots(): Promise<Lot[]> {
  const data = await railsGet(`/lots/upcoming`);
  return await deserializeData(data);
}

async function parkingLot(): Promise<ParkingLot> {
  const data = await railsGet(`/lots/parking`);
  return await deserializeData(data);
}

async function createParkingLot(body: any): Promise<ParkingLot> {
  const data = await railsPost(`/lots/parking`, toSnakeCaseKeys(body));
  return await deserializeData(data);
}

async function deleteParkingLot(): Promise<any> {
  const data = await railsDelete(`/lots/parking`);
  return await deserializeData(data);
}

async function getEvents(page: number): Promise<any> {
  const data = await railsGet(`/events?page=${page}`);
  const result = await deserializeData(data);

  return {
    data: result,
    meta: data.meta,
  };
}

async function identityVerifications(userId: string): Promise<any[]> {
  const data = await railsGet(
    `/users/${userId}/bidder_registration/identity_verifications`
  );
  return await deserializeData(data);
}

async function approveIdentityVerification(
  userId: string,
  identityVerificationId: string
): Promise<any> {
  const data = await railsPost(
    `/users/${userId}/bidder_registration/identity_verifications/${identityVerificationId}/approve`
  );

  return await deserializeData(data);
}

async function rejectIdentityVerification(
  userId: string,
  identityVerificationId: string
): Promise<any> {
  const data = await railsPost(
    `/users/${userId}/bidder_registration/identity_verifications/${identityVerificationId}/reject`
  );
  return await deserializeData(data);
}

async function createIdentityVerification(userId: string): Promise<any> {
  const data = await railsPost(
    `/users/${userId}/bidder_registration/identity_verifications`
  );
  return await deserializeData(data);
}

async function createConditionsOfSale(userId: string): Promise<any> {
  const data = await railsPost(
    `/users/${userId}/bidder_registration/conditions_of_sale_acceptance`
  );
  return await deserializeData(data);
}

async function createExtraFees(userId: string): Promise<any> {
  const data = await railsPost(
    `/users/${userId}/bidder_registration/extra_fees_acceptance`
  );
  return await deserializeData(data);
}

async function createShippingAddress(userId: string): Promise<any> {
  const data = await railsPost(
    `/users/${userId}/bidder_registration/shipping_address`
  );
  return await deserializeData(data);
}

async function toggleBiddingLimit(userId: string): Promise<any> {
  const data = await railsPut(
    `/users/${userId}/bidder_registration/bidding_limit_enabled`
  );
  return await deserializeData(data);
}

async function lotImages(lotId: string): Promise<any> {
  const data = await railsGet(`/lots/${lotId}/images`);
  return await deserializeData(data);
}

async function deleteLotImage(lotId: string, imageId: string): Promise<any> {
  const data = await railsDelete(`/lots/${lotId}/images/${imageId}`);
  return await deserializeData(data);
}

async function deleteLotComparable(
  lotId: string,
  comparableId: string
): Promise<any> {
  return railsDelete(`/lots/${lotId}/comparables/${comparableId}`);
}

function sectionPositions(lotId: string, body: any): Promise<any> {
  return railsPut(`/lots/${lotId}/sections/positions`, body);
}

async function imagePositions(lotId: string, body: any): Promise<any> {
  const data = await railsPut(`/lots/${lotId}/images/positions`, {
    data: body,
  });
  return await deserializeData(data);
}

async function lotSections(lotId: string): Promise<any> {
  const data = await railsGet(`/lots/${lotId}/sections`);
  return await deserializeData(data);
}

function createSection(lotId: string, body: any): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  const form = deepFormData(bodyData, formData);

  return railsPostMultipart(`/lots/${lotId}/sections`, form);
}

function updateSection(
  lotId: string,
  sectionId: string,
  body: any
): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  const form = deepFormData(bodyData, formData);

  return railsPutMultiPart(`/lots/${lotId}/sections/${sectionId}`, form);
}

function deleteLotSection(lotId: string, sectionId: string): Promise<any> {
  return railsDelete(`/lots/${lotId}/sections/${sectionId}`);
}

async function lotComparables(lotId: string): Promise<any> {
  const data = await railsGet(`/lots/${lotId}/comparables`);
  return await deserializeData(data);
}

function comparablePositions(lotId: string, body: any): Promise<any> {
  return railsPut(`/lots/${lotId}/comparables/positions`, body);
}

async function getMassNotifications(): Promise<any> {
  const data = await railsGet('/mass_notifications');
  const result = await deserializeData(data);

  return {
    data: result,
    meta: data.meta,
  };
}

function createMassNotification(
  type: string,
  channel: string,
  message: string,
  emails?: Array<string> | null,
  title?: string | null
): Promise<any> {
  return railsPost('/mass_notifications', {
    type,
    channel,
    message,
    emails,
    title,
  });
}

async function massNotificationsEvents(page: number): Promise<any> {
  const data = await railsGet(`/mass_notifications/events?page=${page}&per=10`);
  const result = await deserializeData(data);

  return {
    data: result,
    meta: data.meta,
  };
}

function massRegistrations(lotId: string): Promise<any> {
  return railsPost(`/lots/${lotId}/mass_registration`);
}

function updateLotOnApp(lotId: string): Promise<any> {
  return railsPost(`/lots/${lotId}/send_event`);
}

async function createAlert(body: any): Promise<any> {
  const data = await railsPost('/auction/alerts', body);
  return await deserializeData(data);
}

async function createPastLot(body: any): Promise<PastLot> {
  const data = await railsPost('/lots/pasts', toSnakeCaseKeys(body));
  return await deserializeData(data);
}

async function pastLots(): Promise<Array<PastLot>> {
  const data = await railsGet('/lots/pasts');
  return await deserializeData(data);
}

async function updatePastLot(id: string, body: any): Promise<PastLot> {
  const data = await railsPut(`/lots/pasts/${id}`, toSnakeCaseKeys(body));
  return await deserializeData(data);
}

async function deletePastLot(id: string): Promise<void> {
  return railsDelete(`/lots/pasts/${id}`);
}

async function updatePastLotsPositions(body: any): Promise<void> {
  const data = await railsPut('/lots/pasts/positions', { data: body });
  return await deserializeData(data);
}

async function getImage(url: string): Promise<any> {
  return downloadImage(url);
}

async function getSettings(): Promise<any> {
  const data = await railsGet('/settings');
  return await deserializeData(data);
}

async function updateSetting(id: string, body: any): Promise<Setting> {
  const data = await patch(`/settings/${id}`, body);
  return await deserializeData(data);
}

function replaceMasterImage(lotId: string, file: any, body: any): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  formData.append('lot[master_image_file]', file);
  formData.append('lot[master_image_width]', bodyData.width);
  formData.append('lot[master_image_height]', bodyData.height);
  const form = deepFormData(bodyData, formData);

  return railsPutMultiPart(`/lots/${lotId}`, form);
}

function replaceFullScreenImage(
  lotId: string,
  file: any,
  body: any
): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  formData.append('lot[full_screen_image_file]', file);
  formData.append('lot[full_screen_image_width]', bodyData.width);
  formData.append('lot[full_screen_image_height]', bodyData.height);
  const form = deepFormData(bodyData, formData);

  return railsPutMultiPart(`/lots/${lotId}`, form);
}

function addImage(lotId: string, file: any, body: any): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  formData.append('full_size_image_file', file);
  formData.append('full_size_image_width', bodyData.width);
  formData.append('full_size_image_height', bodyData.height);
  const form = deepFormData(bodyData, formData);

  return railsPostMultipart(`/lots/${lotId}/images`, form);
}

function addThumbnailImage(
  lotId: string,
  imageId: string,
  file: any,
  body: any
): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  formData.append('thumbnail_image_file', file);
  formData.append('thumbnail_image_width', bodyData.width);
  formData.append('thumbnail_image_height', bodyData.height);
  const form = deepFormData(bodyData, formData);

  return railsPutMultiPart(`/lots/${lotId}/images/${imageId}`, form);
}

function addComparable(lotId: string, file: any, body: any): Promise<any> {
  const formData = new FormData();
  formData.append('file_file', file);
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  return railsPostMultipart(`/lots/${lotId}/comparables`, formData);
}

function updateComparable(
  lotId: string,
  comparableId: string,
  file: any,
  body: any
): Promise<any> {
  const formData = new FormData();

  if (file) {
    formData.append('file_file', file);
  }

  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  return railsPut(`/lots/${lotId}/comparables/${comparableId}`, formData);
}

function replacePastLotThumbnail(
  pastLotId: string,
  file: any,
  body: any
): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  formData.append('thumbnail_image_file', file);
  formData.append('thumbnail_image_width', bodyData.width);
  formData.append('thumbnail_image_height', bodyData.height);
  const form = deepFormData(bodyData, formData);

  return railsPutMultiPart(`/lots/pasts/${pastLotId}`, form);
}

async function replacePastLotVideo(pastLotId: string, body: any): Promise<any> {
  const newBody = {
    vimeo_id: body.vimeoId,
    vimeo_width: body.weight,
    vimeo_height: body.height,
  };

  const data = railsPut(`/lots/pasts/${pastLotId}`, toSnakeCaseKeys(newBody));
  return await deserializeData(data);
}

function importLot(file: any): Promise<any> {
  const formData = new FormData();
  formData.append('file', file);
  return railsPostMultipart(`/lots/import`, formData);
}

function replacePastLotDetailImage(
  pastLotId: string,
  file: any,
  body: any
): Promise<any> {
  const bodyData = toSnakeCaseKeys(body);
  const formData = new FormData();
  formData.append('detail_image_file', file);
  formData.append('detail_image_width', bodyData.width);
  formData.append('detail_image_height', bodyData.height);
  const form = deepFormData(bodyData, formData);

  return railsPutMultiPart(`/lots/pasts/${pastLotId}`, form);
}

async function upload(
  file: any,
  newStorageUrl: any,
  createUrl: any,
  body: any
): Promise<any> {
  const uploadUrl = await post(newStorageUrl, { fileName: file.name });
  await gcp.storage.put(uploadUrl.putUrl, file);
  return await post(createUrl, { ...uploadUrl, ...body });
}

async function updateFullScreenVimeo(
  lotId: string,
  vimeoId: string,
  width?: number,
  height?: number
): Promise<any> {
  const body = {
    full_screen_vimeo_id: vimeoId,
    full_screen_vimeo_width: width,
    full_screen_vimeo_height: height,
  };
  const data = await railsPut(`/lots/${lotId}`, { lot: body });
  return await deserializeData(data);
}

async function updateMasterVimeo(
  lotId: string,
  vimeoId: string,
  width?: number,
  height?: number
): Promise<any> {
  const body = {
    master_vimeo_id: vimeoId,
    master_vimeo_width: width,
    master_vimeo_height: height,
  };
  const data = await railsPut(`/lots/${lotId}`, { lot: body });
  return await deserializeData(data);
}

async function updateLotImageVimeo(
  lotId: string,
  imageId: string,
  vimeoId: string,
  width: number,
  height: number
): Promise<VimeoVideo> {
  const body = { vimeoId: vimeoId, vimeoWidth: width, vimeoHeight: height };
  const data = await railsPut(
    `/lots/${lotId}/images/${imageId}`,
    toSnakeCaseKeys(body)
  );
  return await deserializeData(data);
}

function updateLotComparableVimeo(
  lotId: string,
  comparableId: string,
  vimeoId: string,
  width: number,
  height: number
): Promise<VimeoVideo> {
  const body = { vimeo_id: vimeoId, width, height };
  const data = railsPut(`/lots/${lotId}/comparables/${comparableId}`, body);
  return deserializeData(data);
}

function deleteMasterVimeo(lotId: string): Promise<void> {
  return railsPut(`/lots/${lotId}`, {
    lot: {
      master_vimeo_id: null,
      master_vimeo_width: null,
      master_vimeo_height: null,
    },
  });
}

function deleteFullScreenVimeo(lotId: string): Promise<void> {
  return railsPut(`/lots/${lotId}`, {
    lot: {
      full_screen_vimeo_id: null,
      full_screen_vimeo_width: null,
      full_screen_vimeo_height: null,
    },
  });
}

async function deleteLotComparableVimeo(
  lotId: string,
  comparableId: string
): Promise<any> {
  const body = { vimeo_id: null, vimeo_width: null, vimeo_height: null };
  const data = await railsPut(
    `/lots/${lotId}/comparables/${comparableId}`,
    body
  );
  return await deserializeData(data);
}

function deleteLotImageVimeo(lotId: string, imageId: string): Promise<void> {
  return railsDelete(`/lots/${lotId}/images/${imageId}/vimeo`);
}

async function getLotRsvp(lotId: string): Promise<any[]> {
  const data = await railsGet(`/lots/${lotId}/rsvps`);
  const deserialized = await deserializeData(data);
  if (!deserialized.length) return deserialized;

  return deserialized.map((rsvp: any) => ({
    ...rsvp,
    ...rsvp.user,
  }));
}

async function getFeatures(): Promise<{ data: any }> {
  const data = await railsGet(`/features`);
  const result = await deserializeData(data);

  return {
    data: result,
  };
}

async function getCurrentParkingLotV2(): Promise<any> {
  const data = await railsGet(`/lots/parkings_v2/current`);
  if (!data || !data.data) return null;

  return await deserializeData(data);
}

async function getParkingLotsV2(): Promise<any> {
  const data = await railsGet(`/lots/parkings_v2`);
  const response = await deserializeData(data);

  return {
    data: response,
  };
}

async function forceRefreshParkingLotV2(): Promise<any> {
  const data = await railsPost(`/lots/parkings_v2/force_refresh`);
  return await deserializeData(data);
}

async function createParkingLotV2(body: any): Promise<any> {
  const parsedBody = toSnakeCaseKeys(body);
  const data = await railsPost(`/lots/parkings_v2`, parsedBody);
  return await deserializeData(data);
}

async function enableParkingLotV2(id: string): Promise<any> {
  const data = await railsPut(`/lots/parkings_v2/${id}/enable`);
  return await deserializeData(data);
}

async function updateParkingLotV2(id: string, body: any): Promise<any> {
  const parsedBody = toSnakeCaseKeys(body);
  const data = await railsPut(`/lots/parkings_v2/${id}`, parsedBody);
  return await deserializeData(data);
}

async function deleteParkingLotV2(id: string): Promise<any> {
  return await railsDelete(`/lots/parkings_v2/${id}`);
}

async function getReferralCodes(
  page: number
): Promise<{ data: any; meta: any }> {
  const data = await railsGet(`/referral_codes?page=${page}`);
  const result = await deserializeData(data);

  return {
    data: result,
    meta: data.meta,
  };
}

async function updateFeatures(id: string, body: any): Promise<any> {
  const data = await railsPut(`/features/${id}`, body);
  return await deserializeData(data);
}

async function createReferralCode(body: any): Promise<any> {
  const data = await railsPost(`/referral_codes`, body);
  return await deserializeData(data);
}

async function updateReferralCode(id: string, body: any): Promise<any> {
  const data = await railsPut(`/referral_codes/${id}`, body);
  return await deserializeData(data);
}

async function destroyReferralCode(id: string): Promise<any> {
  return railsDelete(`/referral_codes/${id}`);
}

async function deserializeData(data: any) {
  if (!data || !data.data) {
    return data;
  }

  const deserialized = await new deserializer({
    id: 'id',
    keyForAttribute: 'camelCase',
    typeAsAttribute: false,
  }).deserialize(data);

  return deserialized;
}

const bidders = {
  _delete: deleteBidders,
};

const user = {
  all: users,
  allById,
  usersByEmail,
  get: getUser,
  getRailsUser,
  accept,
  resetBidder,
  triggerApprovedEmail,
  updateUserOnApp,
  getPlaidAccounts,
  syncPlaidAccounts,
  financialVerificationUpdate,
  me,
  signOut,
  update: updateUser,
  updateRailsUser,
  sellRequests: userSellRequests,
  bidderRegistration: getBidderRegistration,
  changeBidderRegistrationState,
  createAccount: createUserAccount,
  promoteAccount,
  events: userEvents,
  userEventsByType,
  identityVerifications,
  approveIdentityVerification,
  rejectIdentityVerification,
  createIdentityVerification,
  createConditionsOfSale,
  createExtraFees,
  createShippingAddress,
  downloadCsvUsersReport,
  toggleBiddingLimit,
  mfaRequest: requestMfaVerification,
  confirmMfa,
  questionnaire,
  questionnaireVersions,
  createQuestionnaire,
};

const lot = {
  all: lots,
  get: getLot,
  create: createLot,
  createSection,
  updateSection,
  update: updateLot,
  updateMultipartLot,
  updateActive: updateLotActive,
  updateAuctionLive: updateLotAuctionLive,
  upcoming: upcomingLots,
  registrations: lotRegistrations,
  registrationsUnpaginated: lotRegistrationsUnpaginated,
  events: lotEvents,
  biddingRoomEvents: lotBiddingRoomEvents,
  replaceMasterImage,
  replaceFullScreenImage,
  images: lotImages,
  deleteImage: deleteLotImage,
  deleteComparable: deleteLotComparable,
  addImage,
  addThumbnailImage,
  imagePositions,
  comparables: lotComparables,
  addComparable,
  updateComparable,
  comparablePositions,
  massRegistrations,
  updateLotOnApp,
  parkingLot,
  createParkingLot,
  deleteParkingLot,
  leaderboard: lotLeaderboard,
  sections: lotSections,
  deleteSection: deleteLotSection,
  sectionPositions,
  updateFullScreenVimeo,
  updateMasterVimeo,
  updateLotImageVimeo,
  updateLotComparableVimeo,
  deleteMasterVimeo,
  deleteFullScreenVimeo,
  deleteLotComparableVimeo,
  deleteLotImageVimeo,
  getLotRsvp,
  import: importLot,
};

const event = {
  all: getEvents,
};

const account = {
  updateBidding: updateAccountBidding,
};

const massNotifications = {
  get: getMassNotifications,
  create: createMassNotification,
  events: massNotificationsEvents,
};

const auction = {
  createAlert,
};

const pastLot = {
  all: pastLots,
  create: createPastLot,
  update: updatePastLot,
  delete: deletePastLot,
  updatePositions: updatePastLotsPositions,
  updateThumbnail: replacePastLotThumbnail,
  updateVideo: replacePastLotVideo,
  updateDetailImage: replacePastLotDetailImage,
};

const testing = {
  createUser: createTestUser,
  getImage,
};

const settings = {
  get: getSettings,
  update: updateSetting,
};

const referralCodes = {
  all: getReferralCodes,
  create: createReferralCode,
  update: updateReferralCode,
  delete: destroyReferralCode,
};

const features = {
  all: getFeatures,
  update: updateFeatures,
};

const parkingLots = {
  all: getParkingLotsV2,
  current: getCurrentParkingLotV2,
  forceRefreshParkingLotV2,
  create: createParkingLotV2,
  enable: enableParkingLotV2,
  update: updateParkingLotV2,
  delete: deleteParkingLotV2,
};

export {
  user,
  bidders,
  settings,
  lot,
  event,
  account,
  massNotifications,
  auction,
  testing,
  pastLot,
  referralCodes,
  features,
  parkingLots,
};
