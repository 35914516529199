import * as React from 'react'
import {FC, useCallback, useEffect, useState} from 'react'
import { Toast } from "./Index";
import * as backend from '../Backend';
import {Column, DataTableRow} from "../Components/DataTable";
import {ReferralCodeForm} from "../Components/ReferralCodeForm";
import * as O from 'fp-ts/lib/Option'

const ReferralCodesPage: FC<{ toast: (toast: Toast) => void }> = ({ toast }) => {
    const [page, setPage] = useState(1)
    const [referralCodes, setReferralCodes] = useState<any>([])
    const [meta, setMeta] = useState<any>({})
    const [showForm, setShowForm] = useState(false)
    const fetchPage = useCallback((page) => {
        return backend.referralCodes.all(page)
    }, [])

    useEffect(() => {
        fetchPage(page).then((results) => {
            setReferralCodes(results.data)
            setMeta(results.meta)
        })
    }, [page]);

    const columns: Column[] = [
        ['code', 'Code'],
        ['active', 'Active'],
        ['autoAccept', 'Auto-Accept'],
        ['usersCount', 'Users Count'],
        ['delete', '', ({ entity }: any) => (
            <button className="button is-small cursor-pointer" disabled={entity.usersCount > 0} onClick={() => destroy(entity)}>Delete</button>
        )],
    ]

    const onClose = (forceReload = false) => {
        setShowForm(false)
        if (forceReload) {
            setPage(1)
            fetchPage(1).then((results) => {
                setReferralCodes(results.data)
                setMeta(results.meta)
            })
        }
    }

    const update = async (referralCode: any) => {
        await backend.referralCodes.update(referralCode.id, { referral_code: { active: !referralCode.active } })
        await fetchPage(page)

        const newReferralCodes = referralCodes.map((r: any) => {
            if (r.id === referralCode.id) {
                r.active = !r.active
            }

            return r
        })

        setReferralCodes(newReferralCodes)

        return O.none
    }

    const onAutoAccept = async (referralCode: any) => {
        await backend.referralCodes.update(referralCode.id, { referral_code: { auto_accept: !referralCode.autoAccept } })
        await fetchPage(page)

        const newReferralCodes = referralCodes.map((r: any) => {
            if (r.id === referralCode.id) {
                r.autoAccept = !r.autoAccept
            }

            return r
        })

        setReferralCodes(newReferralCodes)

        return O.none
    }

    const destroy = async (referralCode: any) => {
        await backend.referralCodes.delete(referralCode.id)
        toast({ type: 'good', message: 'Deleted' })

        const results = await fetchPage(page)
        setReferralCodes(results.data)
        setMeta(results.meta)

        return O.none
    }

    const actions = [
        { action: update, label: 'Change Active' },
        { action: onAutoAccept, label: 'Change Auto-Accept' },
    ]

    if (showForm) {
        return <ReferralCodeForm onClose={onClose} />
    }

    return (
        <div>
            <div className="columns is-vcentered">
                <div className="column">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <button className="button is-primary" onClick={() => setShowForm(true)}>New Referral Code</button>
                        </ul>
                    </nav>
                </div>
            </div>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    {columns.map(([_, label]) => <th key={label}>{label}</th>)}
                    {actions.map((action) => <th key={action.label}/>)}
                </tr>
                </thead>
                <tbody>
                {
                    referralCodes.length === 0 ? <tr>
                            <td>There's nothing here.</td>
                        </tr> :
                        referralCodes.map((u: any) => {
                            return <DataTableRow key={u['id']} entity={u} columns={columns} actions={actions} />
                        })
                }
                </tbody>
            </table>
            {referralCodes.length === 0 ? <></> :
                <nav className="pagination" role="navigation" aria-label="pagination">
                    <button className="pagination-previous" disabled={page === 1}
                            onClick={() => setPage(page - 1)}>
                        Previous
                    </button>
                    <button className="pagination-next" disabled={page === meta.total_pages} onClick={() => setPage(page + 1)}>
                        Next page
                    </button>
                    <ul className="pagination-list">
                        <li>
                            <button disabled={page === 1} className='pagination-link' onClick={() => setPage(1)}>First</button>
                        </li>
                        <li>
                            <button className="pagination-link is-current">{page} of {meta.total_pages}</button>
                        </li>
                        <li>
                            <button disabled={page === meta.total_pages}
                                    className='pagination-link' onClick={() => setPage(meta.total_pages)}>Last
                            </button>
                        </li>
                    </ul>
                </nav>
            }
        </div>
    )
}

export default ReferralCodesPage
