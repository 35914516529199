import { FC } from 'react'
import React from 'react'
import Vimeo from '@u-wave/react-vimeo'

type IProps = {
  video: string,
  autoplay?: boolean,
  loop?: boolean,
  width?: number,
  height?: number,
  visible: boolean,
  toast: any,
  onCancel: () => void
}

const VimeoPlayerModal: FC<IProps> = ({ video, autoplay, loop, width, height, visible, onCancel, toast }) => {
  if (!video) {
    return null
  }
  return <div className={`modal ${visible ? 'is-active' : ''}`}>
    <div className="modal-background" onClick={onCancel}></div>
    <div className="modal-content">
      <Vimeo video={video}
             onError={() => toast({ type: 'bad', message: `No video found` })}
             loop={loop || false}
             autoplay={autoplay || false}></Vimeo>
      <article className="message is-info">
        <div className="message-body">
          Dimensions: {width || 'unknown'} x {height || 'unknown'}
        </div>
      </article>
    </div>
    <button className="modal-close is-large" onClick={onCancel} />
  </div>
}

export default VimeoPlayerModal
